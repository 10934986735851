import { AvInput } from "availity-reactstrap-validation";
import { useEffect } from "react";
import ReactSelect from "react-select";
import { Label } from "reactstrap";

const options = [
  {
    label: "USD - Default",
    value: {
      accountNo: "U48442527",
      currency: "USD",
    }
  },
  {
    label: "EUR - Default",
    value: {
      accountNo: "E46093729",
      currency: "EUR",
    }
  },
  // {
  //   label: "GBP - Default",
  //   value: {
  //     accountNo: "G46173247",
  //     currency: "GBP",
  //   }
  // }
];

export default function PerfectMoney(props) {

  const {
    t, setIsFirstStepValid, setPaymentPayload, setTargetCurrency, perfectMoneyId, setPerfectMoneyId, setMinAmount, setMaxAmount
  } = props;
  useEffect(()=>{ 
    setMinAmount(10);
    setMaxAmount(50000);
  }), [];  
  const onChangeHandler = (e) => {
    const targetCurrency = e.value.currency;
    //console.log("E", targetCurrency);
    setTargetCurrency(targetCurrency);
    if (e) {
      setPaymentPayload({
        ...e.value,
        gateway: "PERFECT_MONEY"
      });
      setIsFirstStepValid(true);
    } else {
      setPaymentPayload({});
      setIsFirstStepValid(false);
    }
  };

  const onChangePerfectMoneyId = (e) => {
    setPerfectMoneyId(e.target.value);
    if (e) {
      setPaymentPayload({
        ...e.value,
        gateway: "PERFECT_MONEY",
      });
      setIsFirstStepValid(true);
    } else {
      setPaymentPayload({});
      setIsFirstStepValid(false);
    }
  };
  
  return (
    <div className="d-flex justify-content-between align-items-start">
      <div className="w-50">
        <p className="text-muted">{t("Select the deposit account")}</p>
        <div className="mb-3">
          <Label>{t("Account ")}</Label>
          <ReactSelect
            name="account"
            onChange={onChangeHandler}
            required
            placeholder="Select Account"
            options={options}
          ></ReactSelect>
        </div>
     
      </div>
      {/* <div className="mb-3">
        <Label>{t("Perfect Money Wallet Id ")}</Label>
        <AvInput
          name="perfectMoneyId"
          onChange={onChangePerfectMoneyId}
          required
          placeholder="Add Perfect Money Wallet Id"
        ></AvInput>
      </div> */}
      <div className="mb-3 w-50 ms-5 flex-1">

        <div className="mb-2">

          <p className="text-secondary">
            {
              "If a customer deposits money with perfect money, the PM ID  name must be the same as the registration name. This is because, when you request a withdrawal from our company, we will transfer the funds back to the same user PM wallet address. (For third-party payments, funds cannot be deposited)."
            }
          </p>
        </div>
        <div>
          <h6>Deposit limits per transaction</h6>
          <p>10 USD -  50,000 USD</p>
        </div>
      </div>
    </div>
  );
} 