// import { getMyFatoorahPaymentMethods } from "apis/deposit";
// import Loader from "components/Common/Loader";
// import React, {
//   useEffect, useState, useMemo
// } from "react";
// import Cards from "react-credit-cards-2";
// import "react-credit-cards-2/dist/es/styles-compiled.css";

// export default function MyFatoorah({
//   t, setIsFirstStepValid, setPaymentPayload, paymentPayload
// }) {
//   const [selectedMethod, setSelectedMethod] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [methods, setMethods] = useState([]);
//   const [showCard, setShowCard] = useState(false);
//   const [card, setCard] = useState({
//     number: "",
//     expiry: "",
//     name: "",
//     focus: "",
//     cvc: "",
//   });
//   const [isCardValid, setIsCardValid] = useState(false);

//   const handleMethodChange = (method) => {
//     setSelectedMethod(method);
//     console.log("method", method);
//     if (method) {
//       setPaymentPayload({
//         PaymentMethodId: method.PaymentMethodId,
//         PaymentMethod: method.PaymentMethodCode,
//       });
//       if (method.IsDirectPayment) {
//         setShowCard(true);
//       } else {
//         setIsFirstStepValid(true);
//         setShowCard(false);
//       }
//     } else {
//       setPaymentPayload({});
//       setIsFirstStepValid(false);
//       setShowCard(false);
//       setCard({
//         number: "",
//         expiry: "",
//         cvc: "",
//         name: "",
//         focus: "",
//       });
//     }
//   };

//   useEffect(() => {
//     loadMethods();
//   }, []);

//   const loadMethods = async () => {
//     setIsLoading(true);
//     try {
//       const response = await getMyFatoorahPaymentMethods();
//       setMethods(response?.result?.paymentMethods || []);
//     } catch (error) {
//       console.error("Error loading payment methods:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const memoizedMethods = useMemo(
//     () => methods.filter((method) => method.PaymentMethodCode === "uaecc"),
//     [methods]
//   );

//   const handleCardChange = (e) => {
//     const { name, value } = e.target;
//     console.log("name", name, "value", value);
//     setCard(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleInputFocus = (e) => {
//     setCard(prev => ({
//       ...prev,
//       focus: e.target.name
//     }));
//   };

//   useEffect(() => {
//     if (isCardValid) {
//       setIsFirstStepValid(true);
//       console.log("card", card);
//       setPaymentPayload({
//         ...paymentPayload,
//         card: {
//           ...card,
//           expiryYear: card.expiry.slice(2, 4),
//           expiryMonth: card.expiry.slice(0, 2),
//         }
//       });
//     } else {
//       setIsFirstStepValid(false);

//     }
//   }, [isCardValid]);
  
//   return (
//     <div className="container-fluid p-4">
//       {isLoading ? (
//         <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
//           <Loader />
//         </div>
//       ) : (
//         <div className="row row-cols-2 row-cols-md-4 g-4 justify-content-center my-2">
//           {memoizedMethods.map((method) => (
//             <div key={method?.PaymentMethodId} className="col d-flex justify-content-center pb-4">
//               <div>
//                 <button
//                   className={`btn btn-outline-light w-100 h-100 d-flex align-items-center justify-content-center ${selectedMethod === method ? "active" : ""}`}
//                   onClick={() => handleMethodChange(method)}
//                 >
//                   <img
//                     src={method?.ImageUrl}
//                     alt={method?.PaymentMethodEn}
//                     className="img-fluid"
//                     style={{ maxHeight: "40px" }}
//                   />
//                 </button>
//                 <p className="text-center">{method?.PaymentMethodEn} - {method?.PaymentCurrencyIso}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//       {showCard && (
//         <div className="mt-4">
//           <Cards
//             number={parseInt(card.number)}
//             expiry={card.expiry}
//             cvc={parseInt(card.cvc)}
//             name={card.name}
//             focused={card.focus}
//             callback={(_, isValid) => {
//               console.log("CARD: ", card);
//               console.log("DONE CC: ", isValid);
//               setIsCardValid(isValid);
//             }}
//           />
//           <form className="mt-4">
//             <div className="mb-3">
//               <input
//                 type="tel"
//                 name="number"
//                 placeholder="Card Number"
//                 value={card.number}
//                 onChange={handleCardChange}
//                 onFocus={handleInputFocus}
//                 className="form-control"
//               />
//             </div>
//             <div className="mb-3">
//               <input
//                 type="text"
//                 name="name"
//                 placeholder="Name"
//                 value={card.name}
//                 onChange={handleCardChange}
//                 onFocus={handleInputFocus}
//                 className="form-control"
//               />
//             </div>
//             <div className="row">
//               <div className="col-6 mb-3">
//                 <input
//                   type="text"
//                   name="expiry"
//                   placeholder="MM/YY"
//                   value={card.expiry}
//                   onChange={handleCardChange}
//                   onFocus={handleInputFocus}
//                   className="form-control"
//                 />
//               </div>
//               <div className="col-6 mb-3">
//                 <input
//                   type="tel"
//                   name="cvc"
//                   placeholder="CVC"
//                   value={card.cvc}
//                   onChange={handleCardChange}
//                   onFocus={handleInputFocus}
//                   className="form-control"
//                 />
//               </div>
//             </div>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// }

import { getMyFatoorahPaymentMethods } from "apis/deposit";
import Loader from "components/Common/Loader";
import React, {
  useEffect, useState, useMemo 
} from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

export default function MyFatoorah({
  t,
  setIsFirstStepValid,
  setPaymentPayload,
  paymentPayload,
  setMinAmount,
  setMaxAmount,
}) {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [methods, setMethods] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [card, setCard] = useState({
    number: "",
    expiryMonth: "",
    expiryYear: "",
    name: "",
    focus: "",
    cvc: "",
    expiry: "",
  });
  const [isCardValid, setIsCardValid] = useState(false);
  useEffect(()=>{ 
    setMinAmount(50);
    setMaxAmount(15000);
  }), [];  
  const handleMethodChange = (method) => {
    console.log("this is method", method);
    setSelectedMethod(method);
    if (method) {
      setPaymentPayload({
        PaymentMethodId: method.PaymentMethodId,
        PaymentMethod: method.PaymentMethodCode,
      });
      if (method.IsDirectPayment) {
        setIsFirstStepValid(true);
        setShowCard(true);
      } else {
        setIsFirstStepValid(true);
        setShowCard(false);
      }
    } 
    else {
      setPaymentPayload({});
      setIsFirstStepValid(false);
      setShowCard(false);
      setCard({
        number: "",
        expiry: "",
        cvc: "",
        name: "",
        focus: "",
        expiryMonth: "",
        expiryYear: "",
      });
    }
  };

  useEffect(() => {
    loadMethods();
  }, []);

  const loadMethods = async () => {
    setIsLoading(true);
    try {
      const response = await getMyFatoorahPaymentMethods();
      setMethods(response?.result?.paymentMethods || []);
    } catch (error) {
      console.error("Error loading payment methods:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const memoizedMethods = useMemo(
    () => methods.filter((method) => method.PaymentMethodCode === "uaecc"),
    [methods]
  );

  const handleCardChange = (e) => {
    const { name, value } = e.target;
    setCard((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputFocus = (e) => {
    setCard((prev) => ({
      ...prev,
      focus: e.target.name,
    }));
  };

  const validateCard = () => {
    const { number, expiryMonth, expiryYear, cvc, name } = card;
    const numberValid = number.length === 16;
    const expiryValid = expiryMonth.length === 2 && expiryYear.length === 2;
    const cvcValid = cvc.length === 3;
    const nameValid = name.trim().length > 0;
    return numberValid && expiryValid && cvcValid && nameValid;
  };

  useEffect(() => {
    const cardIsValid = validateCard();
    setIsCardValid(cardIsValid);
    // setIsFirstStepValid(cardIsValid);
    setPaymentPayload({
      ...paymentPayload,
      card: {
        ...card,
        expiryYear: card.expiryYear,
        expiryMonth: card.expiryMonth,
      },
    });
  }, [card]);
  console.log("this is card", card);
  return (
    <div className="container-fluid p-4 d-flex justify-content-between align-items-center">
      <div className="w-50">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "400px" }}
          >
            <Loader />
          </div>
        ) : (
          <div className="row row-cols-2 row-cols-md-4 g-4 justify-content-center  my-2">
            {memoizedMethods?.map((method) => (
              <div
                key={method?.PaymentMethodId}
                className="col d-flex justify-content-center pb-4"
              >
                <div>
                  <button
                    className={`btn btn-outline-light w-100 h-100 d-flex align-items-center justify-content-center ${
                      selectedMethod === method ? "active" : ""
                    }`}
                    onClick={() => handleMethodChange(method)}
                  >
                    <img
                      src={method?.ImageUrl}
                      alt={method?.PaymentMethodEn}
                      className="img-fluid"
                      style={{ maxHeight: "400px" }}
                    />
                  </button>
                  <p className="text-center">
                    {method?.PaymentMethodEn} - {method?.PaymentCurrencyIso}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        {showCard && (
          <div className="mt-4">
            <Cards
              number={card?.number}
              expiry={card?.expiry}
              cvc={card?.cvc}
              name={card?.name }
              focused={card?.focus}
              callback={({ isValid }) => {
                // Updated to extract isValid directly from callback data.
                console.log("Validation Result:", isValid);
                setIsCardValid(isValid);
              }}
            />
            <form className="mt-4">
              <div className="mb-3">
                <input
                  type="tel"
                  name="number"
                  placeholder="Card Number"
                  value={card?.number}
                  onChange={handleCardChange}
                  onFocus={handleInputFocus}
                  className="form-control"
                  maxLength="16"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={card?.name}
                  onChange={handleCardChange}
                  onFocus={handleInputFocus}
                  className="form-control"
                />
              </div>
              <div className="row">
                <div className="col-6 mb-3">
                  <input
                    type="text"
                    name="expiry"
                    placeholder="MM/YY"
                    value={card?.expiry}
                    onChange={handleCardChange}
                    onFocus={handleInputFocus}
                    className="form-control"
                    maxLength="5"
                  />
                </div>
                <div className="col-6 mb-3">
                  <input
                    type="tel"
                    name="cvc"
                    placeholder="CVC"
                    value={card?.cvc}
                    onChange={handleCardChange}
                    onFocus={handleInputFocus}
                    className="form-control"
                    maxLength="3"
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="mb-3 w-50 ms-5 flex-1">

        <div className="mb-2">

          <p className="text-secondary">
            {
              "If a customer deposits money with the bank, the bank account name must be the same as the registration name. This is because, when you request a withdrawal from our company, we will transfer the funds back to the same user bank account.To process your deposit, please upload a color copy of both the front and back sides of your card ."
            }
          </p>
        </div>
        <div className="mb-2">
          <h5 className="my-1">{"showing the following details:"}</h5>
          <ul>
            <li>{"1- Full name of the cardholder."}</li>
            <li>
              {
                "2- Last 4 digits of your card number."
              }
            </li>
            <li>
              {
                "3- Card expiry date."
              }
            </li>
          </ul>
        </div>
        <div>
          <h5 className="my-1">{"Important Information:"}</h5>
          <p className="text-secondary">
            {
              "*For security purposes, we recommend covering all digits except the last 4 on the front side of the card. Additionally, please cover the 3 digits CVV code on your card (We do not require CVV code). All other details must be clear and visible."
            }
          </p>
          <p className="text-secondary">
            {
              "visa ,master card deposit will need to charge 2.75% handling fees and withdrawal will need to charge 1% handling fees  and withdrawal finish typically will take 5 to 14 days."
            }
          </p>
          <h6>Minimum deposit limit: 50 USD</h6>
          <h6>Maximum deposit limit: 15,000 USD</h6>
        </div>
      </div>

    </div>
  );
}
