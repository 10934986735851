import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import {
  Modal, ModalHeader, ModalBody, Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { createChangeSwapRequest } from "store/actions";
import Loader from "components/Common/Loader";
import { useEffect } from "react";

const SwapModal = ({ isOpen, toggle, accounts, t }) => {
  const dispatch = useDispatch();
  const createChangeLeverageRequestLoadings = useSelector(state => state.forex);
  const createChangeSwapRequestLoading = useSelector(state => state.forex.requests.createChangeSwapRequestLoading);
  const { clientData } = useSelector(state => state.Profile);

  const handleValidSubmit = (event, values) => {
    dispatch(createChangeSwapRequest({
      swapType: clientData?.customerSwapType === undefined ? "SWAP_FREE" : "SWAP",
      customerId: clientData?._id
    }));
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t(`Change to ${clientData?.customerSwapType === undefined ? "SWAP_FREE" : "SWAP"}`)}
      </ModalHeader>
      <ModalBody>
        <div className="alert alert-warning text-center">
          {`You Will Change All Your Accounts To ${clientData?.customerSwapType === undefined ? "SWAP_FREE" : "SWAP"}`}
        </div>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="text-center mt-3 mb-1">
            {
              createChangeSwapRequestLoading
                ?
                <Loader />
                :
                <Button type="submit" className="border-0 color-bg-btn shadow">{t("Change")}</Button>
            }
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(SwapModal);