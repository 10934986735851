export const FETCH_BANK_ACCOUNTS_FAILED = "FETCH_BANK_ACCOUNTS_FAILED";
export const FETCH_BANK_ACCOUNTS_START = "FETCH_BANK_ACCOUNTS_START";
export const FETCH_BANK_ACCOUNTS_SUCCESS = "FETCH_BANK_ACCOUNTS_SUCCESS";
export const UPDATE_BANK_ACCOUNTS_START = "UPDATE_BANK_ACCOUNTS_START";
export const UPDATE_BANK_ACCOUNTS_FAILED = "UPDATE_BANK_ACCOUNTS_FAILED";
export const UPDATE_BANK_ACCOUNTS_SUCCESS = "UPDATE_BANK_ACCOUNTS_SUCCESS";
export const DELETE_BANK_ACCOUNTS_START = " DELETE_BANK_ACCOUNTS_START";
export const  DELETE_BANK_ACCOUNTS_FAILED = " DELETE_BANK_ACCOUNTS_FAILED";
export const  DELETE_BANK_ACCOUNTS_SUCCESS = " DELETE_BANK_ACCOUNTS_SUCCESS";

export const FETCH_CRYPTO_METHOD_FAILED = "FETCH_CRYPTO_METHODS_FAILED";
export const FETCH_CRYPTO_METHOD_START = "FETCH_CRYPTO_METHODS_START";
export const FETCH_CRYPTO_METHOD_SUCCESS = "FETCH_CRYPTO_METHODS_SUCCESS";
export const UPDATE_CRYPTO_METHOD_START = "UPDATE_CRYPTO_METHODS_START";
export const UPDATE_CRYPTO_METHOD_FAILED = "UPDATE_CRYPTO_METHODS_FAILED";
export const UPDATE_CRYPTO_METHOD_SUCCESS = "UPDATE_CRYPTO_METHODS_SUCCESS";
export const DELETE_CRYPTO_METHOD_START = " DELETE_CRYPTO_METHODS_START";
export const DELETE_CRYPTO_METHOD_FAILED = " DELETE_CRYPTO_METHODS_FAILED";
export const DELETE_CRYPTO_METHOD_SUCCESS = " DELETE_CRYPTO_METHODS_SUCCESS";