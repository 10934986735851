import {
  Container, Row,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CardWrapper from "components/Common/CardWrapper";
import PageHeader from "components/Forex/Common/PageHeader";
import Journey from "components/Journey/Journey";
import Platform from "./Platform";
import config from "config";
import { useState } from "react";
import { startTradingJourneyAPI } from "apis/journey";
import { CLIENT_OR_IB } from "common/constants";
import { Button, ButtonGroup } from "reactstrap";
import classNames from "classnames";
import ZendeskChat from "zendesk";
/* eslint-disable object-property-newline */
const PLATFORMS = config.PLATFORMS;

const Platforms = (props) => {
  const [platformDownloaded, setPlatformDownloaded] = useState(null);
  const [customActiveTab, setcustomActiveTab] = useState("MT5");
  const { subPortal } = useSelector(state => state.Layout);
  const onDownload = async (platform) => {
    if (platform.downloadLink) {
      window.open(platform.downloadLink, "_blank");
      const success = await startTradingJourneyAPI();
      if (success?.status)   
        setPlatformDownloaded(true);
    }
  };
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  return (
    <>
      <MetaTags>
        <title>{props.t("Platforms")}</title>
      </MetaTags>
      <Container>
        <div className="page-content mt-5">
          <PageHeader title="Platforms Download"></PageHeader>
          {
            subPortal === CLIENT_OR_IB.CLIENT &&
            <div className="dashboard mt-4">
              <Journey platformDownloaded={platformDownloaded} />
            </div>
          }
          <div>
            <CardWrapper className={`${subPortal === CLIENT_OR_IB.IB && "mt-3"} p-4 glass-card shadow-lg my-5`}>
              <ButtonGroup className="mt-2 mb-4 shadow">
                <Button
                  className={classNames("btn btn-light border-0 ", {
                    "shadow-lg text-white color-bg-btn": customActiveTab === "MT5",
                  })}
                  onClick={() => {
                    toggleCustom("MT5");
                  }}
                >
                  {"MT5"}
                </Button>
                <Button
                  className={classNames("btn btn-light border-0", {
                    "shadow-lg text-white color-bg-btn": customActiveTab === "MT4",
                  })}
                  onClick={() => {
                    toggleCustom("MT4");
                  }}
                >
                  {"MT4"}
                </Button>
              </ButtonGroup>
              <div className="d-flex justify-content-between">
                <h3 className="color-primary">{props.t("Platforms")}</h3>
                <i className="bx bx-dots-vertical-rounded fs-3 mt-1"></i>
              </div>
              <Row className="mt-2 g-4">
                { PLATFORMS.filter((platform) => platform.platform === customActiveTab).map((platform, indx) => <Platform key={indx}
                  image={platform.image}
                  logo={platform.logo}
                  title={platform.name}
                  onDownload={()=>{
                    onDownload(platform);
                  }} />) }
              </Row>
            </CardWrapper>
          </div>
        </div>
      </Container>
      <ZendeskChat />
    </>
  );
};

export default withTranslation()(Platforms);