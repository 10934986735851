import React from "react";
import { connect, useSelector } from "react-redux";
import {
  CardBody, CardTitle, Modal, Button, ModalHeader
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CloseButton from "react-bootstrap/CloseButton";

const getKYCStatus = (status) => {
  if (!status) return "Pending";
  if (status === "DUPLICATE") return "is being processed and may take a while.";
  if (status === "APPROVED") return "is Approved";
  if (status === "REJECTED") return "is Rejected";
  if (status === "REQUIRES ACTION") return " is being processed by our team and may take a while.";
  if (status === "AWAITING DOCUMENTS") return "Client have not uploaded the ID";
  if (status === "PENDING") return "is being processed by Sumsub";
  if (status === "PROCESSING") return "is being processed by Sumsub";
  if (status === "REJECT") return "is Temporarily reject the application";
  if (status === "CLIENT TERMINATION") return "is Rejected";
  if (status === "AWAITING POA") return "is pending. You need to upload the POA";
  if (status === "QUESTIONNAIRE") return "is pending. You need to complete the questionnaire";
  return "is being processed and may take a while.";
};

const SubmitProfile = (props) => {
  const history = useHistory();
  const { clientData } = useSelector((state) => state.Profile);
  return (<React.Fragment>
    <Modal
      isOpen={props.show}
      toggle={() => props.toggle()}
      centered={true}
      //   size="lg"
      className='custom-modal'
    >
      <div style={{
        padding: 20,
      }}>
        <ModalHeader className="d-flex flex-column gap-3">
          <CloseButton
            onClick={() => props.toggle()}
            style={{ 
              alignSelf: "flex-end",
              position: "absolute", 
              right: 10,
              top: 10 
            }} 
          />
          <div className="text-center">
            <CardTitle className="mb-0">{props.t("Your KYC Status is ")} {getKYCStatus(clientData?.audit?.status?.toUpperCase())}</CardTitle>
          </div>
        </ModalHeader>
        <CardBody>
          <h4 className="m-5 text-center">{props.t("Your KYC Status is ")} {getKYCStatus(clientData?.audit?.status?.toUpperCase())}</h4>
          <div className="text-center">
            {/* <Button onClick={props.toggle} color="danger" className="w-lg waves-effect waves-light m-2">
              {props.t("Skip")}
            </Button> */}
            <Button onClick={() => { 
              if (history.location.pathname === "/documents") {
                props.toggle();
              }
              else {
                props.toggle();
                history.push("/documents");
              }
            }} color="success" className="w-lg waves-effect waves-light m-2">
              {props.t("Continue")}
            </Button>
          </div>
        </CardBody>
      </div>
    </Modal>
  </React.Fragment>);
};

const mapStateToProps = (state) => ({
  profile: state.Profile && state.Profile.clientData || {},
});
export default connect(mapStateToProps, null)(withTranslation()(SubmitProfile)); 