export const REQ_IB_START = "REQ_IB_START";
export const REQ_IB_SUCCESS = "REQ_IB_SUCCESS";
export const REQ_IB_FAIL = "REQ_IB_FAIL";

export const GET_IB_REQUEST_STATUS = "GET_IB_REQUEST_STATUS";
export const GET_IB_REQUEST_STATUS_SUCCESS = "GET_IB_REQUEST_STATUS_SUCCESS";
export const GET_IB_REQUEST_STATUS_FAIL = "GET_IB_REQUEST_STATUS_FAIL";

export const GET_SWAP_REQUEST_STATUS = "GET_SWAP_REQUEST_STATUS";
export const GET_SWAP_REQUEST_STATUS_SUCCESS = "GET_SWAP_REQUEST_STATUS_SUCCESS";
export const GET_SWAP_REQUEST_STATUS_FAIL = "GET_SWAP_REQUEST_STATUS_FAIL";

export const GET_SWAP_FREE_REQUEST_STATUS = "GET_SWAP_FREE_REQUEST_STATUS";
export const GET_SWAP_FREE_REQUEST_STATUS_SUCCESS = "GET_SWAP_FREE_REQUEST_STATUS_SUCCESS";
export const GET_SWAP_FREE_REQUEST_STATUS_FAIL = "GET_SWAP_FREE_REQUEST_STATUS_FAIL";

// change leverage request
export const CREATE_CHANGE_LEVERAGE_REQ_REQUESTED =
  "CREATE_CHANGE_LEVERAGE_REQ_REQUESTED";
export const CREATE_CHANGE_LEVERAGE_REQ_SUCCESS =
  "CREATE_CHANGE_LEVERAGE_REQ_SUCCESS";
export const CREATE_CHANGE_LEVERAGE_REQ_FAIL =
  "CREATE_CHANGE_LEVERAGE_REQ_FAIL";

// change swap request
export const CREATE_CHANGE_SWAP_REQUESTED = "CREATE_CHANGE_SWAP_REQUESTED";
export const CREATE_CHANGE_SWAP_SUCCESS = "CREATE_CHANGE_SWAP_SUCCESS";
export const CREATE_CHANGE_SWAP_FAIL = "CREATE_CHANGE_SWAP_FAIL";

//unlink account request
export const POST_UNLINK_REQ_REQUESTED = "POST_UNLINK_REQ_REQUESTED";
export const POST_UNLINK_REQ_SUCCESS = "POST_UNLINK_REQ_SUCCESS";
export const POST_UNLINK_REQ_FAIL = "POST_UNLINK_REQ_FAIL";

//link account request
export const POST_LINK_REQ_REQUESTED = "POST_LINK_REQ_REQUESTED";
export const POST_LINK_REQ_SUCCESS = "POST_LINK_REQ_SUCCESS";
export const POST_LINK_REQ_FAIL = "POST_LINK_REQ_FAIL";

export const REQ_INVESTOR_START = "REQ_INVESTOR_START";
export const REQ_INVESTOR_SUCCESS = "REQ_INVESTOR_SUCCESS";
export const REQ_INVESTOR_FAIL = "REQ_INVESTOR_FAIL";

export const GET_INVESTOR_REQUEST_STATUS = "GET_INVESTOR_REQUEST_STATUS";
export const GET_INVESTOR_REQUEST_STATUS_SUCCESS =
  "GET_INVESTOR_REQUEST_STATUS_SUCCESS";
export const GET_INVESTOR_REQUEST_STATUS_FAIL =
  "GET_INVESTOR_REQUEST_STATUS_FAIL";

export const REQ_MASTER_START = "REQ_MASTER_START";
export const REQ_MASTER_SUCCESS = "REQ_MASTER_SUCCESS";
export const REQ_MASTER_FAIL = "REQ_MASTER_FAIL";

export const GET_MASTER_REQUEST_STATUS = "GET_MASTER_REQUEST_STATUS";
export const GET_MASTER_REQUEST_STATUS_SUCCESS =
  "GET_MASTER_REQUEST_STATUS_SUCCESS";
export const GET_MASTER_REQUEST_STATUS_FAIL = "GET_MASTER_REQUEST_STATUS_FAIL";
