import React from "react";
import { 
  InputGroup, InputGroupText, Label, Modal, Button 
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";


const USDT_NETWORK = "TRC20";

function ShowUSDTDetails({
  isOpen,
  toggleOpen = () => {},
  BankAccountData,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleOpen}
        centered={true}
        size="lg"
        className="custom-modal"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close btn btn-soft-dark waves-effect waves-light btn-rounded m-4"
            aria-label="Close"
            onClick={toggleOpen}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h6>{t("USDT Method Details")}</h6>
          <>
            <div className="mb-3">
              <Label className="mb-2 mt-3">{t("Network")}</Label>
              <InputGroup>
                <InputGroupText className="w-100">
                  {BankAccountData?.network}
                </InputGroupText>
              </InputGroup>
            </div>
            <div className="mb-3">
              <Label className="mb-2">{t("USDT Address")}</Label>
              <InputGroup>
                <InputGroupText className="w-100">
                  {BankAccountData?.address}
                </InputGroupText>
              </InputGroup>
            </div>
            <div className="text-center">
              <Button
                className="btn btn-secondary m-2 btn-sm w-lg"
                onClick={() => toggleOpen()}
              >
                {t("Close")}
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
}
export default withTranslation()(ShowUSDTDetails);
