import {
  ADD_PROMO_START,
  ADD_PROMO_END,
  FETCH_PROMO_START,
  FETCH_PROMO_END,
  EDIT_PROMO_START,
  EDIT_PROMO_END,
  DELETE_PROMO_START,
  DELETE_PROMO_END,
} from "./actionTypes";
 
const INIT_STATE = {
  loading: false,
  editDone: false,
  promos: {
    docs: [],
  },
};

const Promo = (state = INIT_STATE, action) => {
  let docs = state.list && state.list.docs || [];
  switch (action.type) {
    case FETCH_PROMO_START:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PROMO_END:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        promos: action.payload.data,
      };

      // case FETCH_PROMO_FAIL:
      //   return {
      //     ...state,
      //     promo: {
      //       ...state.promo,
      //       fetchPromoLoading: false,
      //       promoError: action.payload,
      //     },
      //   };

    case ADD_PROMO_START:
      return {
        ...state,
        adding: true,
      };

    case ADD_PROMO_END:
      if (action.payload.id) {
        docs = state.promos.docs.map(obj => {
          if (obj._id === action.payload.id) {
            return {
              ...obj,
              ...action.payload.data,
            };
          }
          return obj;
        });
      }
      if (action.payload.data && !action.payload.id) {
        docs = [action.payload.data, ...state.servers.docs];
      }
      return {
        ...state,
        adding: false,
        addError: action.payload.error,
        promos: {
          ...state.promos,
          docs,
        },
      };

      // case ADD_PROMO_FAIL:
      //   return {
      //     ...state,
      //     promo: {
      //       ...state.promo,
      //       addPromoLoading: false,
      //       disableAddPromoButton: false,
      //       promoError: action.payload,
      //     },
      //   };

    case DELETE_PROMO_START:
      return {
        ...state,
        deleting: true,
      };

    case DELETE_PROMO_END:
      return {
        ...state,
        deleting: false,
      };

    case EDIT_PROMO_START:
      return {
        ...state,
        editDone: false,
      };

    case EDIT_PROMO_END:
      return {
        ...state,
        editDone: true,
      };
    default:
      return { ...state };
  }
};

export default Promo;
