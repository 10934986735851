// SelectWithdrawalMethodModal.jsx
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button, Row, Col 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { toggleCurrentModal } from "store/actions"; // Ensure this action is correctly defined
import CustomModal from "components/Common/CustomModal"; // Ensure CustomModal is correctly implemented

function SelectWithdrawalWayModal({ isOpen, toggleOpen, ...props }) {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
    }
  }, [isOpen]);

  const steps = [
    {
      header: props.t("Add Withdrawal"),
      content: (
        <div className="text-center my-4" style={{ marginLeft: "20px" }}>
          <h6 className="mb-3">{props.t("Add Withdrawal Method")}</h6>
          <Row className="justify-content-center align-items-center">
            {/* Bank Account Button */}
            <Col xs={12} md={6} lg={6} className="my-2">
              <Button
                type="button"
                style={{
                  backgroundColor: "#3498db", // Bright blue background for Bank Account
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  padding: "15px 20px",
                  fontSize: "18px", // Increased font size for better readability
                  fontWeight: "bold", // Make the text bold
                  transition: "background-color 0.3s ease, color 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#2980b9"; // Darker blue on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#3498db"; // Reset background color
                }}
                onClick={() => {
                  dispatch(toggleCurrentModal("AddBankAccountModal")); // Dispatch action to open the bank account modal
                  //toggleOpen(); // Close the current modal
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{props.t("Bank Account")}</span>
                  <small style={{ color: "#ecf0f1" }}>
                    {props.t("Add a bank account")}
                  </small>{" "}
                  {/* Lighter color for subtitle */}
                </div>
              </Button>
            </Col>

            {/* USDT Method Button */}
            <Col xs={12} md={6} lg={6} className="my-2">
              <Button
                type="button"
                style={{
                  backgroundColor: "#2CA58D", // Green background for USDT Method
                  color: "white",
                  border: "none",
                  borderRadius: "8px",
                  padding: "15px 20px",
                  fontSize: "18px", // Increased font size for better readability
                  fontWeight: "bold", // Make the text bold
                  transition: "background-color 0.3s ease, color 0.3s ease",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#228C74"; // Darker green on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#2CA58D"; // Reset background color
                }}
                onClick={() => {
                  dispatch(toggleCurrentModal("AddUsdtMethodModal")); // Dispatch action to open the USDT modal
                  //toggleOpen()
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{props.t("USDT Method")}</span>
                  <small style={{ color: "#ecf0f1" }}>
                    {props.t("Add a USDT method")}
                  </small>{" "}
                  {/* Lighter color for subtitle */}
                </div>
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomModal
        steps={steps}
        size="md"
        isOpen={isOpen}
        toggleOpen={toggleOpen} // Ensure toggleOpen is correctly handled in parent component
        activeStep={activeStep}
        toggleTab={() =>
          setActiveStep((prevStep) => (prevStep + 1) % steps.length)
        }
      />
    </>
  );
}

export default withTranslation()(SelectWithdrawalWayModal);
