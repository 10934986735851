import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";

import {
  fetchBankAccounts,
  fetchWallets,
  toggleCurrentModal,
} from "../../../store/actions";
import { addWithdraw } from "../../../apis/withdraw";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import calculateFee from "../../../helpers/calculateTranFee";

import CardWrapper from "../../Common/CardWrapper";
import CustomModal from "../../Common/CustomModal";
import AddBankAccountModal from "../../BankAccounts/AddBankAccountModal";
import CustomSelect from "components/Common/CustomSelect";
import Loader from "components/Common/Loader";
import { withdrawalConfig } from "../MT5/withdrawalConfig";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { validateFile } from "helpers/validations/file";
import AsiaBanks from "components/Deposit/Methods/AsiaBanks";
import PerfectMoneyWithdrawal from "components/Deposit/Methods/PerfectMoney/PerfectMoneyWithdrawal";

import AddUsdtMethodModal from "components/UsdtMethod/AddUsdtMethodModal";
import { type } from "jquery";
import { get } from "lodash";
import { getConversionRate } from "apis/conversionRate";


function FiatWithdraw({ isOpen,  toggleOpen, ...props }) {
  const { t } = useTranslation();
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [targetCurrency, setTargetCurrency] = useState("USD");
  const [activeStep, setActiveStep] = useState(0);
  const [amountValidation, setAmountValidation] = useState(false);
  const wallets = useSelector((state) => state.crypto.wallets?.wallets);
  const bankAccounts = useSelector(
    (state) => state.crypto.bankAccounts?.bankAccounts?.docs
  );  
  const tranFeeGroupDetails = useSelector(
    (state) => state.Profile?.clientData?.transactionFeeId
  );

  const amountValidationHandler = (e) => {
    e.target.value < 30
      ? setAmountValidation(true)
      : setAmountValidation(false);
  };

  const fiatWallets = wallets
    ?.filter((wallet) => !wallet.withdrawalDisabled)
    .map((item) => ({
      label: `${item.asset} (${item?.amount})`,
      value: item._id,
      image: getAssetImgSrc(item.assetId),
    }));

  const [selectedWallet, setSelectedWallet] = useState(fiatWallets[0]);
  const [bankAccountId, setBankAccounttId] = useState("");
  const [amount, setAmount] = useState("");
  const [gateway, setGateway] = useState("");
  const [result, setResult] = useState("");
  const [bankAccountDetile, setBankAccountDetile] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [wireTransferFlag, setWireTransferFlag] = useState(false);
  const [transactionFee, setTransactionFee] = useState("");

  const [addBankModal, setAddBankModal] = useState(false);
  const [addBankUsdtModal, setAddBankUsdtModal] = useState(false);
  const [selectBankError, setSelectBankError] = useState(false);
  const [selectUSDTError, setSelectUSDTError] = useState(false);
  const [selectGatwayError, setSelectGatwayError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [selectedWalletError, setSelectWalletError] = useState(false);
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [loading, setLoading] = useState(false);
  const [methodConfig, setMethodConfig] = useState({});
  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [addUsdtModal, setAddUsdtModal] = useState(false);
  const [perfectMoneyId, setPerfectMoneyId] = useState("");
  const [conversionRate, setConversionRate] = useState(0);
  
  useEffect(() => {
    console.log("base-target", baseCurrency, targetCurrency);
    getConversionRate({
      baseCurrency,
      targetCurrency 
    }).then((rate) => {
      setConversionRate(rate);
    });
  }, [baseCurrency, targetCurrency, amount]);

  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setSelectedWallet("");
      setGateway("");
      setAmount("");
      setBankAccounttId("");
      setBankAccountDetile({});
      setLoading(false);
    }
    dispatch(
      fetchBankAccounts({
        limit: 100,
        page: 1,
      })
    );
    return () => {
      setSelectBankError(false);
      setSelectUSDTError(false);
      setSelectGatwayError(false);
      setAmountError(false);
      setSelectWalletError(false);
    };
  }, [isOpen]);

  useEffect(() => {
    if (bankAccountId != "") {
      const detile = bankAccounts.find((o) => o._id === bankAccountId);
      if (detile) {
        setBankAccountDetile(detile);
      }
    }
  }, [bankAccountId]);
    
  useEffect(() => {
    if (
      (gateway === "WIRE_TRANSFER" || gateway === "LOCAL_WIRE_TRANSFER") &&
      bankAccountDetile
    ) {
      setMethodConfig({
        ...methodConfig,
        bankAccount: {
          name: bankAccountDetile?.accountHolderName,
          bankName: bankAccountDetile?.bankName,
          accountNumber: bankAccountDetile?.accountNumber,
          address: bankAccountDetile?.address,
          swiftCode: bankAccountDetile?.swiftCode,
          currency: bankAccountDetile?.currency,
        },
        type: "BANK"
      });
    }
    if (
      gateway === "CRYPTO" && bankAccountDetile
    ) {
      setMethodConfig({
        ...methodConfig,
        bankAccount: {
          network: bankAccountDetile?.network,
          address: bankAccountDetile?.address,
        },
      });
    }
  }, [bankAccountDetile]);
  const dispatch = useDispatch();
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }
  useEffect(() => {
    setTransactionFee(
      tranFeeGroupDetails ? calculateFee(tranFeeGroupDetails, amount) : 0
    );
  }, [tranFeeGroupDetails, amount]);
  const handleSubmit = () => {
    setLoading(true);
    // const formData = new FormData();
    // formData.append("gateway", gateway);
    // formData.append("amount", amount);
    // formData.append("walletId", selectedWallet?.value);
    // formData.append("note", "note");
    // formData.append("payload", `${JSON.stringify(methodConfig)}`);
    // if (methodConfig?.file){
    //   formData.append("receipt", methodConfig?.file);
    // }
    !selectedWalletError &&
      addWithdraw({
        gateway,
        amount,
        walletId: selectedWallet?.value,
        note: "NOTE",
        payload: `${JSON.stringify(methodConfig)}`,
      })
        .then((e) => {
          // console.log(e);
          setLoading(false);
          setResult(e);
          toggleTab(2);
        })
        .catch((e) => {
          // console.log(e);
          setLoading(false);
          setResult(e);
          toggleTab(2);
        });
    // toggleOpen()
  };
  const validateStep1 = () => {
    if (gateway == "WIRE_TRANSFER") {
      if (bankAccountId == "") {
        setSelectBankError(true);
        setTimeout(() => {
          setSelectBankError(false);
        }, 2000);
      }
    }
    
    if (gateway == "CRYPTO") {
      if (bankAccountId == "") {
        setSelectUSDTError(true);
        setTimeout(() => {
          setSelectUSDTError(false);
        }, 2000);
      }
    }
    if (selectedWallet === "") {
      setSelectWalletError(true);
      setTimeout(() => {
        setSelectWalletError(false);
      }, 2000);
    }
    if (gateway == "") {
      setSelectGatwayError(true);
      setTimeout(() => {
        setSelectGatwayError(false);
      }, 2000);
    }
    if (selectedWallet?.value != "" && gateway != "") {
      if (gateway == "WIRE_TRANSFER") {
        if (bankAccountId != "") {
          toggleTab(1);
        }
      } else {
        toggleTab(1);
      }
      if (gateway == "CRYPTO") {
        if (bankAccountId != "") {
          toggleTab(1);
        }
      } else {
        toggleTab(1);
      }
    }
  };
  const validateStep2 = () => {
    if (amount == "") {
      setAmountError(true);
      setTimeout(() => {
        setAmountError(false);
      }, 2000);
    } else {
      handleSubmit();
    }
  };
  useEffect(() => {
    dispatch(
      fetchWallets({
        isCrypto: false,
      })
    );
  }, []);

  const isFirstSubmitValid = () => {
    if (gateway === "") {
      return setIsFirstStepValid(false);
    } else if (
      gateway === "WIRE_TRANSFER" ||
      gateway === "LOCAL_WIRE_TRANSFER"
    ) {
      if (bankAccountId === "") {
        return setIsFirstStepValid(false);
      } else {
        return setIsFirstStepValid(true);
      }
    } else if (gateway === "CRYPTO") {
      console.log(methodConfig);

      if (
        bankAccountDetile.network &&
        bankAccountDetile.currency === "USD" &&
        bankAccountDetile.address
      ) {
        return setIsFirstStepValid(true);
      } else {
        return setIsFirstStepValid(false);
      }
    } else if (gateway === "PERFECT_MONEY") {
      console.log(methodConfig);

      if (
        perfectMoneyId && methodConfig.perfectMoneyId
      ) {
        return setIsFirstStepValid(true);
      } else {
        return setIsFirstStepValid(false);
      }
    } else if (gateway === "صناديق-الاعتماد") {
      if (methodConfig.type === "حواله") {
        if (
          methodConfig.file &&
          methodConfig.locationOfHawala !== "" &&
          methodConfig.locationOfHawala !== null &&
          methodConfig.locationOfHawala !== undefined
        ) {
          return setIsFirstStepValid(true);
        } else {
          return setIsFirstStepValid(false);
        }
      }
      if (methodConfig.type === "اعتماد") {
        if (
          methodConfig.nameOfBox !== "" &&
          methodConfig.numberOfBox !== "" &&
          methodConfig.nameOfBox !== undefined &&
          methodConfig.numberOfBox !== undefined
        ) {
          return setIsFirstStepValid(true);
        } else {
          return setIsFirstStepValid(false);
        }
      }
    }
    return setIsFirstStepValid(false);
  };

  useEffect(() => {
    isFirstSubmitValid();
  }, [gateway, bankAccountId, bankAccounts, methodConfig, activeStep]);

  const steps = [
    {
      header: "Select Method",
      content: (
        <>
          <AvForm>
            <div className="mb-3">
              <h6 className="mb-3">
                {props.t("Select Payment Method")}
                {selectGatwayError && (
                  <p className="small text-danger ">
                    {props.t("Please Select Payment Method")}
                  </p>
                )}
              </h6>
              <Row className="payment-methods justify-content-center">
                {withdrawalConfig
                  .filter((method) => method.allowed.mt5)
                  .map((method, index) => (
                    <Col xs={4} lg={2} className="my-2" key={index}>
                      <button
                        type="button"
                        onClick={() => {
                          setGateway(method.gateway);
                          setSelectedPaymentMethod(method.gateway);
                          setMethodConfig(method);
                          setBankAccounttId("");
                          setBankAccountDetile({});
                        }}
                        onMouseEnter={() => {
                          setHoveredPaymentMethod(method.gateway);
                        }}
                        onMouseLeave={() => {
                          setHoveredPaymentMethod();
                        }}
                        className={`btn btn-${
                          selectedPaymentMethod === method.gateway
                            ? "success"
                            : hoveredPaymentMethod === method.gateway
                              ? "default"
                              : "light"
                        } waves-effect waves-light w-sm py-4`}
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                        }}
                      >
                        <img
                          src={method.image}
                          // width="100%"
                          // height="100%"
                          alt=""
                        ></img>
                      </button>
                    </Col>
                  ))}
                {/* <Col xs={4} lg={2} className="my-2">
                <button
                  type="button"
                  onClick={() => {
                    setGateway("VISA");
                    setWireTransferFlag(false);
                    setSelectedPaymentMethod("VISA");
                  }}
                  onMouseEnter={() => { setHoveredPaymentMethod("VISA") }}
                  onMouseLeave={() => { setHoveredPaymentMethod() }}
                  className={`btn btn-${selectedPaymentMethod === "VISA" ? "success" : hoveredPaymentMethod === "VISA" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                >
                  <img
                    src="img/payment-method/visa.png"
                    width="100%"
                    height="100%"
                    alt=""
                  ></img>
                </button>
              </Col>
              <Col xs={4} lg={2} className="my-2">
                <button
                  type="button"
                  onClick={() => {
                    setGateway("MASTERCARD");
                    setWireTransferFlag(false);
                    setSelectedPaymentMethod("MASTERCARD");
                  }}
                  onMouseEnter={() => { setHoveredPaymentMethod("MASTERCARD") }}
                  onMouseLeave={() => { setHoveredPaymentMethod() }}
                  className={`btn btn-${selectedPaymentMethod === "MASTERCARD" ? "success" : hoveredPaymentMethod === "MASTERCARD" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                >
                  <img
                    src="img/payment-method/mastercard-1.png"
                    width="100%"
                    height="100%"
                    alt=""
                  ></img>
                </button>
              </Col>
              <Col xs={4} lg={2} className="my-2">
                <button
                  type="button"
                  onClick={() => {
                    setGateway("NETELLER");
                    setWireTransferFlag(false);
                    setSelectedPaymentMethod("NETELLER");
                  }}
                  onMouseEnter={() => { setHoveredPaymentMethod("NETELLER") }}
                  onMouseLeave={() => { setHoveredPaymentMethod() }}
                  className={`btn btn-${selectedPaymentMethod === "NETELLER" ? "success" : hoveredPaymentMethod === "NETELLER" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                >
                  <img
                    src="img/payment-method/neteller.png"
                    width="100%"
                    height="100%"
                    alt=""
                  ></img>
                </button>
              </Col>
              <Col xs={4} lg={2} className="my-2">
                <button
                  type="button"
                  onClick={() => {
                    setGateway("SKRILL");
                    setWireTransferFlag(false);
                    setSelectedPaymentMethod("SKRILL");
                  }}
                  onMouseEnter={() => { setHoveredPaymentMethod("SKRILL") }}
                  onMouseLeave={() => { setHoveredPaymentMethod() }}
                  className={`btn btn-${selectedPaymentMethod === "SKRILL" ? "success" : hoveredPaymentMethod === "SKRILL" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                >
                  <img
                    src="img/payment-method/skrill.png"
                    width="100%"
                    height="100%"
                    alt=""
                  ></img>
                </button>
              </Col> */}
              </Row>
            </div>
            {gateway === "PERFECT_MONEY" && (
              <div className="d-flex justify-content-between align-items-start">
                <div className="w-50">
                  <PerfectMoneyWithdrawal
                    t={t}
                    //setIsFirstStepValid={setIsFirstStepValid}
                    methodConfig={methodConfig}
                    setMethodConfig={setMethodConfig}
                    perfectMoneyId={perfectMoneyId}
                    setPerfectMoneyId={setPerfectMoneyId}
                  />
                </div>
                <div className="mb-3 w-50 ms-5 flex-1">
                  <div className="mb-2">
                    <h5 className="my-1">
                      {"Digital Currency Withdrawal Instructions"}
                    </h5>
                    <p className="text-secondary">
                      {
                        "For any initial USDT withdrawal request or if you're using a new USDT address, you'll soon receive a confirmation email from "
                      }
                      <a
                        href="mailto:withdrawal@mhmarkets.com"
                        style={{ fontWeight: "bold" }}
                      >
                        {"withdrawal@mhmarkets.com"}
                      </a>
                      {
                        " to verify the transaction. Kindly respond to this email at your earliest convenience so that we can proceed with the processing of your withdrawal request."
                      }
                    </p>
                    <p className="text-secondary">
                      {
                        "Withdrawal requests are processed on weekdays, i.e., from Monday to Friday, between 04:00 and 13:00 (GMT+3). Any withdrawal requests submitted after 12:00 will be processed on the next business day."
                      }
                    </p>
                  </div>
                  <div className="mb-2">
                    <h5 className="my-1">{"Withdrawal Rules:"}</h5>
                    <ul>
                      <li>{"1- The minimum withdrawal amount is 30 USD."}</li>
                      <li>
                        {
                          "2- Withdrawal requests from the commission account will be processed upon reaching the minimum withdrawal amount."
                        }
                      </li>
                      <li>
                        {
                          "3- In the event of a full balance withdrawal from a trading account falling below the stipulated minimum, you have the option to add funds to your account and then initiate the withdrawal request after achieving the minimum withdrawal amount."
                        }
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h5 className="my-1">{"Important Information:"}</h5>
                    <p className="text-secondary">
                      {
                        "We do not accept payments to third parties. The name of the recipient must match the name registered with MHMarkets."
                      }
                    </p>
                    <p className="text-secondary">
                      {
                        "For cent accounts, please ensure that withdrawal requests are submitted in USD."
                      }
                    </p>
                    <p className="text-secondary">
                      {
                        "The withdrawal currency must match the deposit currency."
                      }
                    </p>
                  </div>
                </div>
              </div>
            )}
            {gateway === "WIRE_TRANSFER" && (
              <div>
                <div className="mb-3 w-100">
                  <Label className="mb-4">
                    {props.t("Select Bank Account")}
                  </Label>
                  <InputGroup>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setBankAccounttId(e.target.value);
                        const chosenBankAccount = bankAccounts.find(
                          (bankAccount) => bankAccount._id === e.target.value
                        );
                        setTargetCurrency(chosenBankAccount?.currency);
                      }}
                      value={bankAccountDetile.bankName}
                    >
                      <option value="" disabled selected hidden>
                        {props.t("Select Bank Account")}
                      </option>
                      {bankAccounts
                        .filter((bankAccount) => bankAccount.type === "BANK")
                        .map((bankAccount) => (
                          <option key={bankAccount._id} value={bankAccount._id}>
                            {bankAccount.bankName}
                          </option>
                        ))}
                    </select>
                    <InputGroupText className="custom-input-group-text">
                      <Link
                        to="#"
                        onClick={() => {
                          setAddBankModal(true);
                        }}
                      >
                        {props.t("Add New Bank Account")}
                      </Link>
                    </InputGroupText>
                  </InputGroup>
                  {selectBankError && (
                    <p className="small text-danger">
                      {props.t("Please Select Bank Account")}
                    </p>
                  )}
                </div>
                {bankAccountDetile?.type === "BANK" &&
                  bankAccountDetile &&
                  bankAccountId &&
                  Object.keys(bankAccountDetile).length !== 0 && (
                  <div className="mb-3 d-flex gap-5">
                    <div className="w-50">
                      <h5 className="my-2">{props.t("Payment details")}</h5>
                      <Label className="mb-2">
                        {props.t("Bank Account")}
                      </Label>

                      <div className="mb-3">
                        <InputGroup>
                          <InputGroupText className="w-100">
                            {bankAccountDetile?.accountHolderName}
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="mb-3">
                        <InputGroup>
                          <InputGroupText className="w-100">
                            {bankAccountDetile?.bankName}
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="mb-3">
                        <InputGroup>
                          <InputGroupText className="w-100">
                            {bankAccountDetile?.accountNumber}
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="mb-3">
                        <InputGroup>
                          <InputGroupText className="w-100">
                            {bankAccountDetile?.address}
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="mb-3">
                        <InputGroup>
                          <InputGroupText className="w-100">
                            {bankAccountDetile?.swiftCode}
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="mb-3">
                        <InputGroup>
                          <InputGroupText className="w-100">
                            {bankAccountDetile?.currency}
                          </InputGroupText>
                        </InputGroup>
                      </div>
                    </div>
                    <div className="w-50">
                      <div className="mb-2">
                        <h5 className="my-1">
                          {"Wallet withdrawal instructions"}
                        </h5>
                        <p className="text-secondary">
                          {
                            "Withdrawal requests are processed on weekdays, i.e., from Monday to Friday, between 04:00 and 13:00 (GMT+3). Any withdrawal requests submitted after 12:00 will be processed on the next business day."
                          }
                        </p>
                      </div>
                      <div className="mb-2">
                        <h5 className="my-1">{"Withdrawal Rules:"}</h5>
                        <ul>
                          <li className="text-secondary">
                            {"1- The minimum withdrawal amount is 30 USD."}
                          </li>
                          <li className="text-secondary">
                            {
                              "2- Withdrawal requests from the commission account will be processed upon reaching the minimum withdrawal amount."
                            }
                          </li>
                          <li className="text-secondary">
                            {
                              "3- In the event of a full balance withdrawal from a trading account falling below the stipulated minimum, you have the option to add funds to your account and then initiate the withdrawal request after achieving the minimum withdrawal amount."
                            }
                          </li>
                          <li className="text-secondary">
                            {
                              "4- only e-wallet deposit can be made e-wallet withdrawal."
                            }
                          </li>
                        </ul>
                      </div>
                      <div className="mb-2">
                        <h5 className="my-1">{"Important Information:"}</h5>
                        <p className="text-secondary">
                          {
                            "We do not accept payments to third parties. The name of the recipient must match the name registered with MHMarkets."
                          }
                        </p>
                        <p className="text-secondary">
                          {
                            "For cent accounts, please ensure that withdrawal requests are submitted in USD."
                          }
                        </p>
                        <p className="text-secondary">
                          {
                            "The withdrawal currency must match the deposit currency."
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {gateway === "ASIA_BANKS" && (
              <AsiaBanks
                t={t}
                setIsFirstStepValid={setIsFirstStepValid}
                setBaseCurrency={setBaseCurrency}
              />
            )}
            {gateway === "CRYPTO" && (
              <>
                <div className="mb-3">
                  <Label>{t("Select USDT Account")}</Label>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 me-2">
                      <AvFieldSelecvt
                        name="cryptoAccount"
                        className="form-select"
                        onChange={(e) => {
                          setBankAccountDetile(e); // Update bankAccount state
                          setMethodConfig({
                            ...methodConfig,
                            bankAccount: e,
                            type: "USDT",
                          });
                        }}
                        required
                        placeholder={t("Select USDT Account")}
                        options={bankAccounts
                          ?.filter((bankAccount) => bankAccount.type === "USDT")
                          ?.map((bankAccount) => ({
                            label: `${bankAccount.network} - ${bankAccount.address}`,
                            value: bankAccount,
                          }))}
                      />
                    </div>
                    <div>
                      <InputGroup>
                        <InputGroupText className="custom-input-group-text mt-4">
                          <Link
                            to="#"
                            onClick={() => {
                              setAddUsdtModal(true);
                            }}
                          >
                            {props.t("Add New")}
                          </Link>
                        </InputGroupText>
                      </InputGroup>
                    </div>
                  </div>
                  {bankAccounts?.filter(
                    (bankAccount) => bankAccount.type === "USDT"
                  ).length === 0 && (
                    <p className="text-danger mt-2">
                      {t(
                        "No USDT accounts available. Please add a USDT account."
                      )}
                    </p>
                  )}
                </div>
                <div className="d-flex gap-5">
                  <div className="w-50">
                    {/* Display USDT account details when selected */}
                    {methodConfig.bankAccount &&
                      methodConfig.bankAccount.type === "USDT" && (
                      <AvForm>
                        <h5 className="mb-4">
                          {props.t("USDT Account Details")}
                        </h5>
                        <div>
                          <Label>{props.t("Network")}</Label>
                          <AvField
                            type="text"
                            name="cryptoNetwork"
                            value={methodConfig.bankAccount.network}
                            validate={{ required: true }}
                            disabled={true}
                          ></AvField>
                        </div>
                        <div>
                          <Label>{props.t("Address")}</Label>
                          <AvField
                            type="text"
                            name="cryptoAddress"
                            value={methodConfig.bankAccount.address}
                            validate={{ required: true }}
                            disabled={true}
                          ></AvField>
                        </div>
                        <p>{props.t("USDT Account")}</p>
                      </AvForm>
                    )}

                    {bankAccountDetile.type === "USDT" &&
                      bankAccountDetile &&
                      Object.keys(bankAccountDetile).length !== 0 && (
                      <>
                        <h5 className="mb-4">{props.t("Payment details")}</h5>
                        <Label className="mb-2">
                          {props.t("USDT Method")}
                        </Label>
                        <div className="mb-3">
                          <InputGroup>
                            <InputGroupText className="w-100">
                              {bankAccountDetile?.network}
                            </InputGroupText>
                          </InputGroup>
                        </div>
                        <div className="mb-3">
                          <InputGroup>
                            <InputGroupText className="w-100">
                              {bankAccountDetile?.address}
                            </InputGroupText>
                          </InputGroup>
                        </div>
                        {/* <p className="text-muted">
                        {props.t("You MUST include the Reference Code in your deposit in order to credit your account!")}
                      </p> */}
                      </>
                    )}
                  </div>
                  {bankAccountDetile.type === "USDT" &&
                    bankAccountDetile &&
                    Object.keys(bankAccountDetile).length !== 0 && (
                    <div className="mb-3 w-50">
                      <div className="mb-2">
                        <h5 className="my-1">
                          {"Digital Currency Withdrawal Instructions"}
                        </h5>
                        <p className="text-secondary">
                          {
                            "For any initial USDT withdrawal request or if you're using a new USDT address, you'll soon receive a confirmation email from "
                          }
                          <a
                            href="mailto:withdrawal@mhmarkets.com"
                            style={{ fontWeight: "bold" }}
                          >
                            {"withdrawal@mhmarkets.com"}
                          </a>
                          {
                            " to verify the transaction. Kindly respond to this email at your earliest convenience so that we can proceed with the processing of your withdrawal request."
                          }
                        </p>
                        <p className="text-secondary">
                          {
                            "Withdrawal requests are processed on weekdays, i.e., from Monday to Friday, between 04:00 and 13:00 (GMT+3). Any withdrawal requests submitted after 12:00 will be processed on the next business day."
                          }
                        </p>
                      </div>
                      <div className="mb-2">
                        <h5 className="my-1">{"Withdrawal Rules:"}</h5>
                        <ul>
                          <li>
                            {"1- The minimum withdrawal amount is 30 USD."}
                          </li>
                          <li>
                            {
                              "2- Withdrawal requests from the commission account will be processed upon reaching the minimum withdrawal amount."
                            }
                          </li>
                          <li>
                            {
                              "3- In the event of a full balance withdrawal from a trading account falling below the stipulated minimum, you have the option to add funds to your account and then initiate the withdrawal request after achieving the minimum withdrawal amount."
                            }
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h5 className="my-1">{"Important Information:"}</h5>
                        <p className="text-secondary">
                          {
                            "We do not accept payments to third parties. The name of the recipient must match the name registered with MHMarkets."
                          }
                        </p>
                        <p className="text-secondary">
                          {
                            "For cent accounts, please ensure that withdrawal requests are submitted in USD."
                          }
                        </p>
                        <p className="text-secondary">
                          {
                            "The withdrawal currency must match the deposit currency."
                          }
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {gateway === "صناديق-الاعتماد" && (
              <>
                <div className="mb-3">
                  <Label>{props.t("Type")}</Label>
                  <AvFieldSelecvt
                    name="sandoqType"
                    className="form-select"
                    placeholder="Select Type"
                    onChange={(e) => {
                      setMethodConfig({
                        ...methodConfig,
                        type: e,
                      });
                    }}
                    options={methodConfig?.config?.methods?.map((option) => {
                      return {
                        label: option?.title,
                        value: option?.title,
                      };
                    })}
                  />
                </div>
                {methodConfig?.type && (
                  <>
                    {methodConfig.type === "حواله" && (
                      <>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="receipt">
                            {props.t("Receipt")}
                          </Label>
                          <AvField
                            type="file"
                            name="receipt"
                            className="form-control form-control-md"
                            errorMessage={props.t("Receipt is required")}
                            onChange={(e) => {
                              setMethodConfig({
                                ...methodConfig,
                                file: e.target.files[0],
                              });
                            }}
                            validate={{
                              required: { value: true },
                              validate: validateFile(
                                ["jpg", "jpeg", "png"],
                                1000000,
                                methodConfig?.file || {},
                                {
                                  sizeValidationMessage: props.t(
                                    "The file size is too large"
                                  ),
                                  extensionValidationMessage: props.t(
                                    "The file extension is not allowed"
                                  ),
                                }
                              ),
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <Label>{props.t("وجه الحواله")}</Label>
                          <AvField
                            name="locationOfHawala"
                            type="text"
                            placeholder="وجه الحواله"
                            onChange={(e) => {
                              setMethodConfig({
                                ...methodConfig,
                                locationOfHawala: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                    {methodConfig.type === "اعتماد" && (
                      <>
                        <div className="mb-3">
                          <Label>{props.t("اسم الصندوق")}</Label>
                          <AvField
                            name="nameOfBox"
                            type="text"
                            placeholder="اسم الصندوق"
                            onChange={(e) => {
                              setMethodConfig({
                                ...methodConfig,
                                nameOfBox: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <Label>{props.t("رقم الصندوق")}</Label>
                          <AvField
                            name="numberOfBox"
                            type="text"
                            placeholder="رقم الصندوق"
                            onChange={(e) => {
                              setMethodConfig({
                                ...methodConfig,
                                numberOfBox: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {gateway && (
              <div>
                <label
                  className="font-weight-bold"
                  style={{
                    color: "#333",
                    fontSize: "1rem",
                  }}
                >
                  Select Wallet
                </label>
                <div className="mt-3">
                  <CustomSelect
                    name="wallets"
                    options={fiatWallets}
                    value={selectedWallet}
                    onChange={(e) => {
                      setSelectedWallet(e);
                      console.log("SELECTED WALLET", e);
                      const currency = e?.label.split(" ")[0];
                      setBaseCurrency(currency);
                    }}
                  ></CustomSelect>
                </div>
              </div>
            )}
            <div className="text-center mt-4">
              <Button
                className="btn btn-primary m-2 btn-sm w-lg"
                onClick={() => {
                  setBankAccounttId("");
                  setBankAccountDetile("");
                  setSelectedWallet("");
                  setMethodConfig({
                    ...methodConfig,
                    perfectMoneyId: "" 
                  });
                  setPerfectMoneyId(""); // Add this to reset Perfect Money ID
                  // setMethodConfig({}); // Reset method config
                  setIsFirstStepValid(false);
                  dispatch(toggleCurrentModal("selectWithdrawalMethodModal"));
                }}
              >
                {props.t("Back")}
              </Button>
              <Button
                className="btn btn-danger waves-effect waves-light w-lg btn-sm"
                onClick={() => {
                  validateStep1();
                }}
                disabled={!isFirstStepValid || (!selectedWallet)}
              >
                {props.t("Continue")}
              </Button>
            </div>
          </AvForm>
        </>
      ),
    },
    {
      header: "Enter Amount",
      content: (
        <>
          {fiatWallets ? (
            <>
              {/* <div className="mb-3">
                <Label className="form-label mb-2">
                  {props.t("Transaction Fee")}
                </Label>
                <InputGroup className="">
                  <InputGroupText className=" w-100">
                    {transactionFee}
                    {"   "}
                    {selectedWallet?.label}
                  </InputGroupText>
                </InputGroup>
              </div> */}
              <div className="mb-3">
                <Label className="form-label mb-2">
                  {props.t("Total Amount")}
                </Label>
                <InputGroup>
                  <Input
                    required
                    onChange={(e) => {
                      setAmount(e.target.value);
                      console.log("Conversion Rate: ", conversionRate);
                      amountValidationHandler(e);
                    }}
                    className="form-control"
                    type="number"
                    min="0"
                    value={amount}
                    placeholder={props.t("Enter total amount")}
                  />
                </InputGroup>
                {amountError && (
                  <p className="small text-danger ">
                    {props.t("Please Enter Amount")}
                  </p>
                )}
                {amountValidation && (
                  <p className="small text-danger ">
                    {props.t("Minimum balance amount is " + 30)}
                  </p>
                )}
              </div>
              {amount &&
                //gateway !== "PERFECT_MONEY" &&
                gateway !== "CRYPTO" && (
                <div className="text-center mt-4 mb-4">
                  <span className="fs-5">
                    {t(
                      "Conversion Rate: " +
                          amount +
                          ` ${baseCurrency}` +
                          " = " +
                          `${parseFloat(
                            conversionRate?.toFixed(3) * amount
                          )?.toFixed(3)}` +
                          ` ${targetCurrency}`
                    )}
                  </span>
                </div>
              )}
              <div className="text-center">
                <Button
                  className="btn btn-primary m-2 btn-sm w-lg"
                  onClick={() => {
                    console.log("SELECTED WALLET --> Back");
                    setBankAccounttId("");
                    setBankAccountDetile("");
                    setSelectedWallet("");
                    setPerfectMoneyId(""); 
                    setAmount(null);
                    toggleTab(0);
                  }}
                >
                  {props.t("Back")}
                </Button>
                <Button
                  className="btn btn-danger m-2 btn-sm w-lg"
                  onClick={() => {
                    setLoading(true);
                    console.log("Validate Step 1");
                    validateStep2();
                  }}
                  disabled={loading || amountValidation}
                >
                  {props.t("Continue")}
                </Button>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      header: "Withdraw status",
      content: (
        <>
          <>
            {result.status ? (
              <>
                <div className="text-center  mb-4">
                  <h1 className="fs-1 mb-5">
                    {props.t("Yay!")} <i className="bx bx-wink-smile"></i>
                  </h1>
                  <p>{props.t("Your successfully a withdrawal")}</p>
                  <p>
                    <span className="positive">
                      {result?.result?.amount?.$numberDecimal}{" "}
                      {result?.result?.currency}
                    </span>
                  </p>
                </div>
                <CardWrapper className="mb-4">
                  <div className="d-flex align-items-center justify-content-between px-5">
                    <div>
                      <div className="text-muted">{props.t("Status")}</div>
                      <div className="positive">{props.t("Completed")}</div>
                    </div>
                    <div>
                      <div className="text-muted">
                        {props.t("Transaction ID")}
                      </div>
                      <div>{result?.result?._id}</div>
                    </div>
                  </div>
                </CardWrapper>
              </>
            ) : (
              <>
                <div className="text-center  mb-4">
                  <h1 className="fs-1 mb-5">
                    {props.t("Oops!")} <i className="bx sad"></i>
                  </h1>
                  <p>
                    {props.t(
                      "Your Withdrawal Request Not Successfully Created"
                    )}
                  </p>
                </div>
                <CardWrapper className="mb-4">
                  <div className="d-flex align-items-center justify-content-between px-5">
                    <div>
                      <div className="positive">{result.message}</div>
                    </div>
                  </div>
                </CardWrapper>
              </>
            )}
          </>
          <div className="text-center">
            <Button
              className="btn btn-danger m-2 btn-sm w-lg"
              onClick={() => {
                setLoading(true);
                toggleOpen();
              }}
              disabled={loading}
            >
              {props.t("Continue")}
            </Button>
          </div>
          {/* <div className="text-center">
                <Button className="btn btn-danger m-2 btn-sm w-lg" onClick={handleSubmit}>View Wallet</Button>
            </div> */}
        </>
      ),
    },
  ];

  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
        size={activeStep === 0 ? "xl" : "lg"}
      ></CustomModal>
      <AddBankAccountModal
        isOpen={addBankModal}
        toggleOpen={() => {
          setAddBankModal(false);
        }}
      ></AddBankAccountModal>
      <AddUsdtMethodModal
        isOpen={addUsdtModal}
        toggleOpen={() => {
          setAddUsdtModal(false);
        }}
      ></AddUsdtMethodModal>
    </>
  );
}
export default withTranslation()(FiatWithdraw);
