import Loader from "components/Common/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  InputGroup,
} from "reactstrap";
import AsiaPayWalletDetails from "./AsiaPayWalletDeposit";

export default function FiatFormDetails(props) {
  const {
    t,
    selectedWallet,
    amount,
    setAmount,
    setAmountValidation,
    baseCurrency,
    conversionRate,
    targetCurrency,
    setTargetCurrency,
    gateway,
    amountValidation,
    minAmount,
    maxAmount
  } = props;
  const dispatch = useDispatch();
  const [amountError, setAmountError] = useState(false);
  const { wallets, loading } = useSelector((state) => state?.walletReducer);
  useEffect(() => {
    if (amount > 0 && amount < 20) {
      setAmountError(true);
      setAmountValidation(false);
    } else {
      setAmountError(false);
      setAmountValidation(true);
    }
  }, [amount, minAmount, selectedWallet]);


  return (
    <div className="w-100">
      <>
        {gateway === "ASIA_BANKS" ? (
          <AsiaPayWalletDetails {...props}></AsiaPayWalletDetails>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                {t("Transaction requirements")}
                <i className="fas fa-info-circle ms-2"></i>
                <div className="mt-3">
                  <InputGroup>
                    <Input
                      required
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      className="form-control"
                      type="number"
                      min="0"
                      value={amount}
                      placeholder={"Enter a deposit amount"}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                {!amountValidation && (
                  <p className="small text-danger">
                    {!amount
                      ? t("Amount is required")
                      : amount < minAmount
                        ? t("Amount must be greater than " + minAmount)
                        : t("Amount must be less than or equal to " + maxAmount)}
                  </p>
                )}

                {/* <div className="text-center fw-bolder mt-4 received-amount">
            <span className="fs-5">{selectedWallet?.label}</span>
            <span className="fs-1">{amount}</span>
          </div> */}
              </>
            )}
            {/* {
          selectedWallet && conversionRate !== 0 && amount > 0 && (
            <div className="text-center mt-4">
              <span className="fs-5">{t("Conversion Rate: " + amount + ` ${targetCurrency}` + " = " + `${parseFloat(conversionRate?.toFixed(3) * amount)?.toFixed(3)}` + ` ${baseCurrency}` )}</span>
            </div>
          )
        } */}
            {selectedWallet && conversionRate !== 0 && amount > 0 && (
              <div className="text-center mt-4">
                <span className="fs-5">
                  {t(
                    `Conversion Rate: ${amount} ${baseCurrency} = ${(
                      conversionRate * amount
                    ).toFixed(3)} ${targetCurrency}`
                  )}
                </span>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}
