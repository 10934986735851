import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import CustomSelect from "components/Common/CustomSelect";
import { setSelectedAccount } from "store/actions";

export default function WireTransfer(props) {
  const {
    t,
    setIsFirstStepValid,
    setBaseCurrency,
    setTargetCurrency,
    isLocal,
    banks,
    selectedAccount,
    setSelectedAccount,
    setMaxAmount,
    setMinAmount
  } = props;
  const [bankAccount, setBankAccount] = useState(null);
  useEffect(()=>{ setMinAmount(1000)}), [];  
  useEffect(() => {
    if (bankAccount && selectedAccount) {
      setIsFirstStepValid(true);
    }
  }, [bankAccount, selectedAccount]);
    
  return (
        
    <div className="w-100">
      <CustomSelect
        name="bankAccount"
        onChange={(e) => {
          setBankAccount(e.value);
          //setBaseCurrency(e.value.currency);
          setTargetCurrency(e.value.currency);
          setSelectedAccount(e.value);
        }}
        required
        placeholder="Select Bank Account"
        options={banks
          // .filter((bank) => bank.bankType === "company")
          .map((bank) => {
            return {
              label:`${bank.bankName}`,
              value: bank
            };
          })}
      >
      </CustomSelect>

      {
        bankAccount && (
          <div  className="my-3 d-flex justify-content-between align-items-start">
            <div className="w-50">  
              <AvForm className="mt-4">
                <h5 className="mb-4">{t("Payment details")}</h5>
                <div>
                  <Label>{t("Beneficiary Name")}</Label>
                  <AvField
                    type="text"
                    name="accountHolderName"
                    value={bankAccount.accountHolderName}
                    validate={{ required:true }}
                    disabled={true}
                  >
                  </AvField>
                </div>
                <div>
                  <Label>{t("Bank Name")}</Label>
                  <AvField
                    type="text"
                    name="bankName"
                    value={bankAccount.bankName}
                    validate={{ required:true }}
                    disabled={true}
                  >
                  </AvField>
                </div>
                <div>
                  <Label>{t("Account Number")}</Label>
                  <AvField
                    type="text"
                    name="accountNumber"
                    value={bankAccount.accountNumber}
                    validate={{ required:true }}
                    disabled={true}
                  >
                  </AvField>
                </div>
                <div>
                  <Label>{t("IBAN")}</Label>
                  <AvField
                    type="text"
                    name="IBAN"
                    value={bankAccount.iban}
                    validate={{ required:true }}
                    disabled={true}
                  >
                  </AvField>
                </div>
                <div>
                  <Label>{t("Address")}</Label>
                  <AvField
                    type="text"
                    name="address"
                    value={bankAccount.address}
                    validate={{ required:true }}
                    disabled={true}
                  >
                  </AvField>
                </div>
                <div>
                  <Label>{t("Swift/IFSC Code")}</Label>
                  <AvField
                    type="text"
                    name="swiftCode"
                    value={bankAccount.swiftCode}
                    validate={{ required:true }}
                    disabled={true}
                  >
                  </AvField>
                </div>
                <div>
                  <Label>{t("Currency")}</Label>
                  <AvField
                    type="text"
                    name="currency"
                    disabled={true}
                    value={bankAccount.currency}
                    validate={{ required:true }}
                  >
                  </AvField>
                </div>
                <p>{t("Bank Account")}</p>
                <p className="text-muted">
                  {isLocal &&
                  <>
                    <h6 className="text-bold">
                      {t("Notice to Depositor")}
                    </h6>
                    <ol style={{ listStyleType: "decimal" }}>
                      <li>
                        {t("Please complete your payment within the time stipulated (1 hour) If the order is canceled due to a timeout, you can contact our customer service or account manager.")}
                      </li>
                      <li>
                        {t("Should there be three canceled orders by the depositor actively or due to timeout on a single day, the system will restrict you from depositing further.")}
                        <br></br>
                        {t("Please complete the payment with your real name payment account (e.g., bank account, Upi account, or Wallet payment account). If you make a payment using a falsified name or with a different account name you will be asked for a video of the buyer.")}
                      </li>
                      {t("The buyer shall bind a valid payment tool registered with the real name used on the Platform for payment.")}
                      <li>
                        {t("For timely transactions, please choose real-time transfer options such as UPI balance to top up balance.")}
                      </li>
                      <li>
                        {t("Transaction Limit is set to  INR 2,00,000/- each transaction.")}
                      </li>
                      <li>
                        {t("The client shall not leave any information in the remittance note; otherwise, the transaction is entitled to be cancelled.")}
                      </li>
                    </ol>
                  </>
                  }
                </p>
              </AvForm>
            
            </div>

            <div className="mb-3 w-50 ms-5 flex-1">

              <div className="mb-2">
                <h5 className="my-1">{"showing the following details:"}</h5>
                <ul>
                  <li>{"1- Please note that third-party deposits are not accepted."}</li>
                  <li>
                    {
                      "2- We do not accept deposits originating from the following countries: Russia, Belarus, Iran, Syria, Yemen, Iraq, Libya, Lebanon, DPRK of Korea, Myanmar, South Sudan, Venezuela, Mauritius, USA, Canada, Australia, Malaysia, Taiwan."
                    }
                  </li>
                  <li>
                    {
                      "3- Deposits where the sender's details do not match the registered MH Markets account will be rejected and returned to the source account. The same applies to funds from any of the prohibited countries listed above."
                    }
                  </li>
                  <li>
                    {
                      "4- MH Markets does not apply any additional charges for deposits. However, please be aware that some international banking institutions may impose fees on transactions such as intermediary bank fees. MH Markets is not responsible for covering any such bank fees. Please contact your bank for information regarding any potential fees that may apply to your transaction."
                    }
                  </li>
                  <li>
                    {
                      "5- The minimum acceptable deposit is 1,000 USD ."
                    }
                  </li>
                  <li>
                    {
                      "6- Please upload the payment confirmation or remittance receipt once the fund transfer is completed."
                    }
                  </li>
                </ul>
              </div>

            </div>
          </div>
        )}

    </div>
  );
}
