import React from "react";
import {
  Card,
  Col,
  Row,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown";

const cardStyles = {
  backgroundColor: "#fff",
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)", 
  borderRadius: "8px",
  padding: "30px",
  width: "100%",
  margin: "0 auto",
  border: "none",
  position: "relative",
};

const logoStyles = {
  width: "150px",
  maxWidth: "80%",
  marginBottom: "30px"
};

const cardItemStyles = {
  display: "flex", 
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "25px",
  border: "1px solid #eaeaea",
  borderRadius: "8px",
  backgroundColor: "#fcfcfc",
  height: "100%"
};

const titleStyles = {
  fontSize: "20px",
  fontWeight: "600",
  color: "#2c3e50",
  marginBottom: "15px",
};

const descriptionStyles = {
  fontSize: "15px",
  color: "#666",
  marginBottom: "25px",
  flex: 1,
  lineHeight: "1.6",
};

const buttonStyles = {
  backgroundColor: "#dc3545",
  border: "none",
  padding: "8px 25px",
  fontSize: "14px",
  borderRadius: "4px",
  width: "100%",
};

const containerStyles = {
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  width: "100%",
  boxSizing: "border-box",
  margin: "0 auto",
};

const AccountSelectionCards = ({ onNewAccount, onNameSakeAccount }) => {
  const { t } = useTranslation();

  return (
    <div style={containerStyles}>
      <Card style={cardStyles}>
        <div style={{
          textAlign: "center",
        }}>
          <img 
            src="/logo.png" 
            alt="MH Markets Logo" 
            style={logoStyles}
          />
        </div>
        
        <div style={{
          position: "absolute",
          display: "block",
          top: -20,
          right: 20,
          zIndex: 999,
        }}>
          <LanguageDropdown />
        </div>
        
        <Row className="g-4" >
          <Col xs={12} md={6}>
            <div style={cardItemStyles}>
              <h5 style={titleStyles}>{t("Register and Open Account")}</h5>
              <p style={descriptionStyles}>
                {t("New customer who have not yet registered CRM.")}
              </p>
              <Button
                color="danger"
                style={buttonStyles}
                onClick={onNewAccount}
              >
                {t("Register")}
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div style={cardItemStyles}>
              <h5 style={titleStyles}>{t("Open Namesake Account")}</h5>
              <p style={descriptionStyles}>
                {t("For registered client, who needs to open namesake account.")}
              </p>
              <Button
                color="danger"
                style={buttonStyles}
                onClick={onNameSakeAccount}
              >
                {t("Login")}
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AccountSelectionCards;