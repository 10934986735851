import AffiliateList from "./AffiliateList";
import AffiliateReport from "./AffiliateReport";
import ZendeskChat from "zendesk";

const Affiliate = () => {
  return (
    <>
      <AffiliateList />
      <AffiliateReport />
      <ZendeskChat />
    </>
  );
};

export default (Affiliate);