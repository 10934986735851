import { cpUrl } from "content";

export default [
  {
    type: "Register Live",
    url: `${cpUrl}/register/client/live`,
    isClient: true,
  },
  // {
  //   type: "Register Demo",
  //   url: `${cpUrl}/register/client/demo`,
  //   isClient: true,
  // },
  {
    type: "Register Sub IB",
    url: `${cpUrl}/register/client/ib`,
    isClient: false,
  },
];
