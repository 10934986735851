/** Api Success and fail */
export const FETCH_PROMO_START = "FETCH_PROMO_START";
export const FETCH_PROMO_END = "FETCH_PROMO_END";
// export const FETCH_PROMO_FAIL = "FETCH_PROMO_FAIL";

export const ADD_PROMO_START = "ADD_PROMO_START";
export const ADD_PROMO_END = "ADD_PROMO_END";
// export const ADD_PROMO_FAIL = "ADD_PROMO_FAIL";
// export const ADD_PROMO_CLEAR = "ADD_PROMO_CLEAR";
// export const ADD_PROMO_ERROR_CLEAR = "ADD_GROUP_ERROR_CLEAR";

export const DELETE_PROMO_START = "DELETE_PROMO_START";
export const DELETE_PROMO_END = "DELETE_PROMO_END";

export const EDIT_PROMO_START = "EDIT_PROMO_START";
export const EDIT_PROMO_END = "EDIT_PROMO_END";
// export const EDIT_PROMO_CLEAR = "EDIT_PROMO_CLEAR";