import config from "config";
import usdt from "assets/images/payment-method/USDT.png";
import myfatoorah from "assets/images/payment-method/myfatoorah.webp";
import PerfectMoney from "assets/images/payment-method/perfectmoney.png";

export const allowedMethods = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/wire-transfer.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
  },
  {
    gateway: "CRYPTO",
    name: "Crypto",
    image: "/img/payment-method/crypto.png",
    allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit"] : [],
    receipt: false,
  },
  // {
  //   gateway: "EPAYME",
  //   name: "ePayMe",
  //   image: epayme,
  //   allowed: ["mt5Deposit", "fiatDeposit"],
  //   receipt: false,
  //   // isSandbox: true
  // },
  {
    gateway: "FINITIC_PAY",
    name: "Finitic Pay",
    image: usdt,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: false,
  },
  {
    gateway: "MYFATOORAH",
    name: "MyFatoorah",
    image: myfatoorah,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: false,
  },
  {
    gateway: "PERFECT_MONEY",
    name: "Perfect Money",
    image: PerfectMoney,
    receipt: false,
    isSandbox: true,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  }
];