import PageHeader from "components/Forex/Common/PageHeader";
import CpTransactions from "pages/Forex/Dashboard/CpTransactions";
import SpAccountDetail from "pages/Forex/Dashboard/SpPortal/SpAccountDetail";
import FeesCollected from "pages/Forex/Investor/Transaction/FeesCollected";
import FeesNotCollected from "pages/Forex/Investor/Transaction/FeesNotCollected";
import { useEffect, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { getAccountDetailsInit } from "store/actions";
import { getMamProfilesInitAction } from "store/forex/mamProfile/actions";
import ZendeskChat from "zendesk";

const InvestorTransaction = () => {
  const dispatch = useDispatch();
  const { profiles, selectedMamAccount: selectedAccount } = useSelector(
    (state) => state.forex.mamProfile
  );

  const selectedTradingAccount = useMemo(() => {
    if (selectedAccount) {
      const filteredAccount = profiles.filter(
        (obj) => obj.masterTradingAccountId.login === selectedAccount.label
      )[0];
      return filteredAccount;
    }
    return null;
  }, [selectedAccount]);

  useEffect(() => {
    dispatch(getMamProfilesInitAction({ params: { type: "sp" } }));
  }, []);

  useEffect(() => {
    if (selectedTradingAccount) {
      dispatch(
        getAccountDetailsInit(selectedTradingAccount.masterTradingAccountId._id)
      );
    }
  }, [selectedTradingAccount]);

  const selectedProfile = useMemo(() => {
    if (selectedTradingAccount) {
      const filteredAccount = profiles.find(
        (obj) => obj.masterTradingAccountId.login === selectedAccount.label
      );
      return filteredAccount;
    }
    return null;
  }, [selectedAccount]);


  return (
    <>
      <MetaTags>
        <title>Investor Transaction</title>
      </MetaTags>
      <Container className="mt-5 forex-dashboard">
        <div className="page-content">
          <PageHeader title="Investor Transaction"></PageHeader>
        </div>
        <div className="pt-5">
          <SpAccountDetail
            selectedTradingAccount={selectedTradingAccount}
            profiles={profiles}
            operations={true}
          />
        </div>
        <FeesCollected selectedProfile={selectedProfile} />
        <div className="pt-5">
          <CpTransactions
            profile={selectedTradingAccount}
            variant={"InvestorDeposit"}
          />
        </div>
        <div className="pt-5">
          <CpTransactions
            profile={selectedTradingAccount}
            variant={"InvestorWithdraw"}
          />
        </div>
      </Container>
      <ZendeskChat />
    </>
  );
};

export default InvestorTransaction;
