import {
  takeEvery, put, call 
} from "redux-saga/effects";

import {
  FETCH_PROMO_START,
  DELETE_PROMO_START,
  EDIT_PROMO_START,
  ADD_PROMO_START,
} from "./actionTypes";

import {
  addPromoEnd,
  fetchPromoEnd,
  deletePromoEnd,
  editPromoEnd,
} from "./actions";

import * as promolinkApi from "../../apis/promolink";
import { showErrorNotification, showSuccessNotification } from "store/general/notifications/actions";
import { delay } from "lodash";

function* fetchPromos(params) {
  try {
    const data = yield call(promolinkApi.getPromolink, params);
    yield put(fetchPromoEnd({ data }));
  }
  catch (error) {
    yield put(fetchPromoEnd({ error }));
  }
}

function* addPromo(params) {
  try {
    console.log("FROM ADD PROMO SAGA: ", params);
    const data = yield call(promolinkApi.addPromolink, params.payload);
    console.log("AFTER ADD PROMO DONE SAGA: ", data);
    yield put(showSuccessNotification("PromoLink added successfully"));
    // yield put(addPromoEnd({
    //   data: params.payload.id ? params.payload : data?.result,
    // }));
  }
  catch (error) {
    yield put(addPromoEnd({ error }));
    yield put(showErrorNotification(error.message));
  }
}

function* deletePromo(params) {
  try {
    yield call(promolinkApi.deletePromolink, params.payload);
    yield put(deletePromoEnd({ id: params.payload }));
    yield put(showSuccessNotification("PromoLink deleted successfully"));
  }
  catch (error) {
    yield put(deletePromoEnd({ error }));
    yield put(showErrorNotification(error.message));
  }
}

function* editPromo(params) {
  try {
    const data = yield call(promolinkApi.editPromolink, params.payload);
    yield put(showSuccessNotification("PromoLink updated successfully"));
    yield put(editPromoEnd(data));
    // if (data) {
    // }
  }
  catch (error) {
    yield put(showErrorNotification(error.message));
  }
}

function* PromoLinkSaga() {
  yield takeEvery(FETCH_PROMO_START, fetchPromos);
  yield takeEvery(ADD_PROMO_START, addPromo);
  yield takeEvery(DELETE_PROMO_START, deletePromo);
  yield takeEvery(EDIT_PROMO_START, editPromo);
}

export default PromoLinkSaga;