import horizontal from "assets/images/brands/logo-sm.png";
import logo from "assets/images/brands/logo-lg.png";
import smallLogo from "assets/images/brands/logo-sm.png";


export const clientName = "MH Markets";
export const developedBy = "MH Markets";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "MH Markets";
export const companyWebsite = "https://mhmarkets.com/";
export const privacyPolicyLink = "#";
export const clientAgreementLink = "#";
export const LPOAAgreementLink = "#";
export const executionPolicy = "#";
export const conflictPolicy = "#";
export const disclosurePolicy = "#";
export const sidebarLogo = logo;
export const smLogo = smallLogo;
export const mainLogo = logo;
export const lightLogo = logo;
export const jwtSecret = process.env.REACT_APP_JWT_SECRET;
