import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./webtrader.scss";
import { Button, ButtonGroup } from "reactstrap";
import classNames from "classnames";

const app_config = {
  clientName: "",
  sidebarTitle: " Client Portal",
  webTraderServer: "MohicansMarkets-Live",
  webTraderVersion: 5,
  webTraderUtmSource: ".com",
  contactUs: "#",
  linkDesktop:
    "#",
  linkAndroid: "#",
  linkIos: "#",
};

const WebTrader = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [height, setHeight] = React.useState(700);
  const [customActiveTab, setcustomActiveTab] = React.useState("1");
  useEffect(() => {
    const { height } = getWindowDimensions();
    setHeight(height - height * 0.1);
  }, []);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <>
      <ButtonGroup className="mt-2 mb-4 shadow">
        <Button
          className={classNames("btn btn-light border-0 ", {
            "shadow-lg text-white color-bg-btn": customActiveTab === "1",
          })}
          onClick={() => {
            toggleCustom("1");
          }}
        >
          {"MT5"}
        </Button>
        <Button
          className={classNames("btn btn-light border-0", {
            "shadow-lg text-white color-bg-btn": customActiveTab === "2",
          })}
          onClick={() => {
            toggleCustom("2");
          }}
        >
          {"MT4"}
        </Button>
      </ButtonGroup>
      {customActiveTab === "1"  && (
        <div id="webterminal" className={"web-terminal"} style={{
          height,
          zIndex: 3,
          position: "relative",
        }}>
          <iframe style={{
            height: "100%",
            width: "100%",
          }} src={`https://mt5web.mohicanstech.com/terminal?servers=${app_config.webTraderServer}&amp;trade_server=${app_config.webTraderServer}&amp;startup_mode=no_autologin&amp;lang=en&amp;save_password=off`} allowfullscreen="allowfullscreen"></iframe>
        </div>
      )}
      {customActiveTab === "2"  && (
        <div id="webterminal" className={"web-terminal"} style={{
          height,
          zIndex: 3,
          position: "relative",
        }}>
          <iframe style={{
            height: "100%",
            width: "100%",
          }} src={`https://metatraderweb.app/trade?version=4&trade_server=Mohicans-Demo&servers=Mohicans-Demo,Mohicans-Live,Mohicans-Live01&lang=en&color_scheme=black_on_white&utm_source=www.mhmarkets.info/terminal?servers=${app_config.webTraderServer}&amp;trade_server=${app_config.webTraderServer}&amp;startup_mode=no_autologin&amp;lang=en&amp;save_password=off`} allowfullscreen="allowfullscreen"></iframe>
        </div>
      )}
      
      <Helmet>
        {/* <script src='https://trade.mql5.com/trade/widget.js'></script> */}

        <script type="text/javascript">
          {/* {`
            setTimeout(() => {
              new MetaTraderWebTerminal( "webterminal", {
                version: ${app_config.webTraderVersion},
                servers: ["${app_config.webTraderServer}"],
                server: "${app_config.webTraderServer}",
                demoAllServers: true,
                utmSource: "${app_config.webTraderUtmSource}",
                startMode: "create_demo",
                language: "en",
                colorScheme: "black_on_white"
            } );
          }, 1000);
          `} */}
        </script>
      </Helmet>
    </>
  );
};

export default WebTrader;
