import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchBankAccounts } from "../../store/actions";
import {
  Button, Container, Input, Label, Modal 
} from "reactstrap";
import { updateBankAccount } from "../../apis/bankAccounts";
import {
  showErrorNotification,
  showSuccessNotification,
} from "store/general/notifications/actions";
import { useTranslation } from "react-i18next";

function EditUsdtMethodModal({
  isOpen,
  toggleOpen = () => {},
  BankAccountData,
}) {
  const dispatch = useDispatch();
  const [network, setNetwork] = useState("");
  const [address, setAddress] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNetwork(BankAccountData?.network || "");
    setAddress(BankAccountData?.address || "");
  }, [isOpen, BankAccountData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateBankAccount({
      id: BankAccountData._id,
      body: {
        network,
        address,
      },
    })
      .then(() => {
        dispatch(showSuccessNotification("USDT Account Updated successfully!"));
        toggleOpen();
        dispatch(
          fetchBankAccounts({
            limit: 100,
            page: 1,
          })
        );
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        dispatch(showErrorNotification(e.toString()));
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleOpen}
        centered={true}
        size="lg"
        className="custom-modal"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close btn btn-soft-dark waves-effect waves-light btn-rounded m-4"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleOpen}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h6>{t("Update USDT Account")}</h6>
          <form onSubmit={handleSubmit}>
            <br />
            <Container>
              <div className="mb-3">
                <Label className="form-label mb-3">{t("Network")}</Label>
                <Input
                  onChange={(e) => setNetwork(e.target.value)}
                  className="form-control"
                  type="text"
                  value={network}
                  placeholder={t("Enter Network")}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label mb-3">{t("Address")}</Label>
                <Input
                  onChange={(e) => setAddress(e.target.value)}
                  className="form-control"
                  type="text"
                  value={address}
                  placeholder={t("Enter Address")}
                />
              </div>
              <div className="text-center">
                <Button
                  className="btn btn-secondary m-2 btn-sm w-lg"
                  onClick={toggleOpen}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  className="btn btn-danger m-2 btn-sm w-lg"
                  type="submit"
                  disabled={loading}
                >
                  {t("Edit")}
                </Button>
              </div>
            </Container>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default EditUsdtMethodModal;
