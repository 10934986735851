import { withTranslation } from "react-i18next";
import FxReport from "./FxReport";
import WalletReport from "./WalletReport";
import IbNotApproved from "components/Common/IbNotApproved";
import { useSelector } from "react-redux";
import ZendeskChat from "zendesk";

const Reports = () => {
  const partnershipAgreement = useSelector((state) => (state.Profile.clientData.stages?.ib?.partnershipAgreement));
  const { subPortal } = useSelector(state => state.Layout);
  const isIbPortal = subPortal === "IB";
  return (
    <>
      {isIbPortal && !partnershipAgreement && <IbNotApproved />}
      <FxReport />
      <WalletReport />
      <ZendeskChat />
    </>
  );
};

export default withTranslation()(Reports);