import { AvInput } from "availity-reactstrap-validation";
import { method } from "lodash";
import ReactSelect from "react-select";
import { Label } from "reactstrap";

const options = [
  {
    label: "USD - Default",
    value: {
      accountNo: "U48442527",
      currency: "USD",
    },
  },
  {
    label: "EUR - Default",
    value: {
      accountNo: "E46093729",
      currency: "EUR",
    },
  },
  // {
  //   label: "GBP - Default",
  //   value: {
  //     accountNo: "G46173247",
  //     currency: "GBP",
  //   }
  // }
];

export default function PerfectMoney(props) {
  const {
    t,
    setMethodConfig,
    methodConfig,
    perfectMoneyId,
    setPerfectMoneyId,
  } = props;
  
  //   const onChangeHandler = (e) => {
  //     const targetCurrency = e.value.currency;
  //     //console.log("E", targetCurrency);
  //     setTargetCurrency(targetCurrency);
  //     if (e) {
  //       setPaymentPayload({
  //         ...e.value,
  //         gateway: "PERFECT_MONEY",
  //       });
  //       setIsFirstStepValid(true);
  //     } else {
  //       setPaymentPayload({});
  //       setIsFirstStepValid(false);
  //     }
  //   };

  const onChangePerfectMoneyId = (e) => {
    setPerfectMoneyId(e.target.value);
    setMethodConfig({
      ...methodConfig,
      perfectMoneyId: e.target.value,
    //   ...e.value,
    //   gateway: "PERFECT_MONEY",
    });
  };

  return (
    <div>
      <p className="text-muted">{t("Select the deposit account")}</p>
      {/* <div className="mb-3">
        <Label>{t("Account ")}</Label>
        <ReactSelect
          name="account"
          onChange={onChangeHandler}
          required
          placeholder="Select Account"
          options={options}
        ></ReactSelect>
      </div> */}
      <div className="mb-3">
        <Label>{t("Perfect Money Wallet Id ")}</Label>
        <AvInput
          name="perfectMoneyId"
          onChange={onChangePerfectMoneyId}
          required
          value={perfectMoneyId}
          placeholder="Add Perfect Money Wallet Id"
        ></AvInput>
      </div>
    </div>
  );
}
