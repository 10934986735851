import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown, DropdownToggle, DropdownMenu, Row, Col
} from "reactstrap";
import SimpleBar from "simplebar-react";

//Import Icons
import FeatherIcon from "feather-icons-react";

//i18n
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications, markNotificationRead } from "store/general/notifications/actions";
import NotificationDropdownItem from "./NotificationDropdownItem";
import TableLoader from "components/Common/Loader";

const NotificationDropdown = props => {
  const { t } = useTranslation();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const {
    notifications,
    totalUnreadNotifications,
    loading,
    layoutMode,
  } = useSelector(state => ({
    notifications: state.notificationsReducer?.unreadNotifications?.docs || [],
    totalUnreadNotifications: state.notificationsReducer?.unreadNotifications?.totalDocs || 0,
    loading: state.notificationsReducer?.loading || false,
    layoutMode: state.Layout.layoutMode,
  }));

  useEffect(() => {
    dispatch(fetchNotifications({
      page: 1,
      limit: 10,
      read: false,
    }));
  }, []);

  const markAsRead = () => {
    dispatch(markNotificationRead({
      all: true
    }));
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <FeatherIcon
            icon="bell"
            className="icon-lg"
            color={layoutMode === "dark" ? "#F89622" : ""}
          />
          {totalUnreadNotifications !== 0 && (
            <span className="badge bg-danger rounded-pill">{totalUnreadNotifications}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {t("Notifications")} </h6>
              </Col>
              {
                !loading && totalUnreadNotifications !== 0 && (
                  <div className="col-auto">
                    <Link
                      to="#"
                      className="small"
                      onClick={markAsRead}
                    >
                      {" "}
                      <i className="mdi mdi-read me-1"></i>
                      {" "}
                      {t("Mark all as read")}
                    </Link>
                  </div>
                )
              }
            </Row>
          </div>

          <SimpleBar style={{ height: "300px" }}>
            {
              loading && <TableLoader />
            }
            {
              !loading && totalUnreadNotifications === 0 && (
                <div className="text-center w-100 mt-4">
                  {t("You have read all your notifications!")}
                </div>
              )
            }
            {
              !loading && notifications?.map((notification, key) => (
                <NotificationDropdownItem key={key} notification={notification} />
              ))
            }
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/notifications"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   Dropdown, DropdownToggle, DropdownMenu, Row, Col 
// } from "reactstrap";
// import SimpleBar from "simplebar-react";

// //Import Icons
// import FeatherIcon from "feather-icons-react";

// //i18n
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchNotifications,
//   markNotificationRead,
// } from "store/general/notifications/actions";
// import NotificationDropdownItem from "./NotificationDropdownItem";
// import TableLoader from "components/Common/Loader";

// const NotificationDropdown = (props) => {
//   const { t } = useTranslation();
//   // Declare a new state variable, which we'll call "menu"
//   const [menu, setMenu] = useState(false);
//   const dispatch = useDispatch();
//   const { notifications, totalUnreadNotifications, loading, layoutMode } =
//     useSelector((state) => ({
//       notifications: [
//         {
//           _id: { $oid: "6717590d3002785230724bc4" },
//           recordId: 10000,
//           fromModel: "users",
//           dir: "ltr",
//           lang: "en",
//           title: "Client Deposit (10000) - APPROVED",
//           body: "The deposit (10000) to account, 4409 (150 USD) for the client testrrr test222 is APPROVED",
//           icon: "https://exinitiweb.blob.core.windows.net/data/assets/FINAL_EXINITI_LOGO_01_24a4e050ba.png",
//           data: {
//             cpClickUrl: "/accounts/live",
//             crmClickUrl: "/transactions/deposit?status=APPROVED}",
//             extraParams: {
//               client: {
//                 firstName: "testrrr",
//                 lastName: "test222",
//                 email: "testrrr test222",
//                 recordId: 10002,
//                 _id: "671756b82254d425883e7f1e",
//               },
//               transaction: {
//                 recordId: 10000,
//                 type: "DEPOSIT",
//                 currency: "USD",
//                 amount: 150,
//                 status: "APPROVED",
//                 platform: "MT5",
//                 login: 4409,
//               },
//             },
//             actions: [],
//           },
//           to: [],
//           createdAt: { $date: new Date() },
//           updatedAt: { $date: "2024-10-22T07:49:33.718Z" },
//           __v: 0,
//         },
//         {
//           _id: { $oid: "6717590d3002785230724bc5" },
//           recordId: 10001,
//           fromModel: "users",
//           dir: "ltr",
//           lang: "en",
//           title: "Client Withdrawal (10001) - PENDING",
//           body: "The withdrawal (10001) from account, 4410 (200 USD) for the client testaaa test333 is PENDING",
//           icon: "https://exinitiweb.blob.core.windows.net/data/assets/FINAL_EXINITI_LOGO_01_24a4e050ba.png",
//           data: {
//             cpClickUrl: "/accounts/live",
//             crmClickUrl: "/transactions/withdrawal?status=PENDING}",
//             extraParams: {
//               client: {
//                 firstName: "testaaa",
//                 lastName: "test333",
//                 email: "testaaa test333",
//                 recordId: 10003,
//                 _id: "671756b82254d425883e7f1f",
//               },
//               transaction: {
//                 recordId: 10001,
//                 type: "WITHDRAWAL",
//                 currency: "USD",
//                 amount: 200,
//                 status: "PENDING",
//                 platform: "MT5",
//                 login: 4410,
//               },
//             },
//             actions: [],
//           },
//           to: [],
//           createdAt: { $date: "2024-10-22T08:49:33.718Z" },
//           updatedAt: { $date: "2024-10-22T08:49:33.718Z" },
//           __v: 0,
//         },
//         {
//           _id: { $oid: "6717590d3002785230724bc6" },
//           recordId: 10002,
//           fromModel: "users",
//           dir: "ltr",
//           lang: "en",
//           title: "Client Transfer (10002) - COMPLETED",
//           body: "The transfer (10002) from account, 4411 (300 USD) for the client testbbb test444 is COMPLETED",
//           icon: "https://exinitiweb.blob.core.windows.net/data/assets/FINAL_EXINITI_LOGO_01_24a4e050ba.png",
//           data: {
//             cpClickUrl: "/accounts/live",
//             crmClickUrl: "/transactions/transfer?status=COMPLETED}",
//             extraParams: {
//               client: {
//                 firstName: "testbbb",
//                 lastName: "test444",
//                 email: "testbbb test444",
//                 recordId: 10004,
//                 _id: "671756b82254d425883e7f20",
//               },
//               transaction: {
//                 recordId: 10002,
//                 type: "TRANSFER",
//                 currency: "USD",
//                 amount: 300,
//                 status: "COMPLETED",
//                 platform: "MT5",
//                 login: 4411,
//               },
//             },
//             actions: [],
//           },
//           to: [],
//           createdAt: { $date: "2024-10-22T09:49:33.718Z" },
//           updatedAt: { $date: "2024-10-22T09:49:33.718Z" },
//           __v: 0,
//         },
//       ],
//       totalUnreadNotifications: 3,
//       loading: false,
//       layoutMode: state.Layout.layoutMode,
//     }));

//   useEffect(() => {
//     dispatch(
//       fetchNotifications({
//         page: 1,
//         limit: 10,
//         read: false,
//       })
//     );
//   }, []);

//   const markAsRead = () => {
//     dispatch(
//       markNotificationRead({
//         all: true,
//       })
//     );
//   };

//   return (
//     <React.Fragment>
//       <Dropdown
//         isOpen={menu}
//         toggle={() => setMenu(!menu)}
//         className="dropdown d-inline-block"
//         tag="li"
//       >
//         <DropdownToggle
//           className="btn header-item noti-icon position-relative"
//           tag="button"
//           id="page-header-notifications-dropdown"
//         >
//           <FeatherIcon
//             icon="bell"
//             className="icon-lg"
//             color={layoutMode === "dark" ? "#F89622" : ""}
//           />
//           {totalUnreadNotifications !== 0 && (
//             <span className="badge bg-danger rounded-pill">
//               {totalUnreadNotifications}
//             </span>
//           )}
//         </DropdownToggle>

//         <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
//           <div className="p-3">
//             <Row className="align-items-center">
//               <Col>
//                 <h6 className="m-0"> {t("Notifications")} </h6>
//               </Col>
//               {!loading && totalUnreadNotifications !== 0 && (
//                 <div className="col-auto">
//                   <Link to="#" className="small" onClick={markAsRead}>
//                     {" "}
//                     <i className="mdi mdi-read me-1"></i>{" "}
//                     {t("Mark all as read")}
//                   </Link>
//                 </div>
//               )}
//             </Row>
//           </div>

//           <SimpleBar style={{ height: "300px" }}>
//             {loading && <TableLoader />}
//             {!loading && totalUnreadNotifications === 0 && (
//               <div className="text-center w-100 mt-4">
//                 {t("You have read all your notifications!")}
//               </div>
//             )}
//             {!loading &&
//               notifications?.map((notification, key) => (
//                 <NotificationDropdownItem
//                   key={key}
//                   notification={notification}
//                 />
//               ))}
//           </SimpleBar>
//           <div className="p-2 border-top d-grid">
//             <Link
//               className="btn btn-sm btn-link font-size-14 btn-block text-center"
//               to="/notifications"
//             >
//               <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
//               {t("View all")}{" "}
//             </Link>
//           </div>
//         </DropdownMenu>
//       </Dropdown>
//     </React.Fragment>
//   );
// };

// export default NotificationDropdown;