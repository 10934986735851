import React from "react";
import { Input, InputGroup } from "reactstrap";
import AsiaPayMt5Details from "./AsiaPayMt5Details";

export default function Mt5Details(props) {
  const { 
    t,
    amount,
    setAmount,
    amountValidation,
    setAmountValidation,
    amountError,
    conversionRate,
    baseCurrency,
    targetCurrency,
    setTargetCurrency,
    minDepositAmount,
    minAmount,
    maxAmount,
    gateway
  } = props;

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    setAmount(value);
    setAmountValidation(value > minAmount);
    console.log("amountValidation: ", amountValidation);
    console.log("actualAmount: ", amount);
    console.log("max amount", maxAmount);
    console.log("minAmount", minAmount);
  };
  return (
    <div className="w-100">
      {gateway === "ASIA_BANKS" ? (
        <>
          <AsiaPayMt5Details {...props}></AsiaPayMt5Details>
        </>
      ) : (
        <>
          <>
            <InputGroup>
              <label className="w-100">{t("Amount in USD")}</label>
              <Input
                required
                onChange={handleAmountChange}
                className="form-control"
                type="number"
                min={minAmount}
                max={maxAmount}
                value={amount}
                placeholder={"Enter Amount in USD"}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </InputGroup>
            {!amountValidation && (
              <p className="small text-danger">
                {!amount
                  ? t("Amount is required")
                  : amount < minAmount
                    ? t("Amount must be greater than " + minAmount)
                    : t("Amount must be less than or equal to " + maxAmount)}
              </p>
            )}
          </>

          {conversionRate !== 0 && amount > 0 && (
            // <div className="text-center mt-4">
            //   <span className="fs-5">
            //     {t(
            //       "Conversion Rate: " +
            //         amount +
            //         " USD" +
            //         " = " +
            //         `${parseFloat(conversionRate?.toFixed(3) * amount)?.toFixed(
            //           3
            //         )}` +
            //         ` ${targetCurrency}`
            //     )}
            //   </span>
            // </div>
            <div className="text-center mt-4">
              <span className="fs-5">
                {t(
                  "Conversion Rate: " +
                    amount +
                    ` ${baseCurrency}` +
                    " = " +
                    `${parseFloat(conversionRate?.toFixed(3) * amount)?.toFixed(
                      3
                    )}` +
                    ` ${targetCurrency}`
                )}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
