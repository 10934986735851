import { deleteBankAccount } from "apis/bankAccounts";
import EditBankAccountModal from "components/BankAccounts/EditBankAccountModal";
import ShowDetails from "components/BankAccounts/ShowDetails";
import ShowUsdtDetails from "components/Usdt/ShowUsdtDetails";
import EditUSDTAccountModal from "components/Usdt/EditUsdtModal";
import CardWrapper from "components/Common/CardWrapper";
import DeleteModal from "components/Common/DeleteModal";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, connect } from "react-redux";
import {
  Button, Col, Container, Row 
} from "reactstrap";
import { toggleCurrentModal } from "store/actions";
import {
  showSuccessNotification,
  showErrorNotification,
} from "store/general/notifications/actions";
import { fetchBankAccounts } from "../../../store/actions";
import ZendeskChat from "zendesk";
import ShowUsdtMethodDetails from "components/UsdtMethod/ShowUsdtMethodDetails";
import EditUsdtMethodModal from "components/UsdtMethod/EditUsdtMethodModal";

function BankAccounts(props) {
  const dispatch = useDispatch();
  const { t, bankAccounts } = props;
  const [showBankModal, setShowBankModal] = useState(false);
  const [updateBankModal, setUpdateBankModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showUsdtModal, setShowUsdtModal] = useState(false);
  const [updateUsdtModal, setUpdateUsdtModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [selectedBankId, setSelectedBankId] = useState("");

  const handelShow = (accountDetails) => {
    setSelectedBank(accountDetails);
    setShowBankModal(true);
  };

  const handelUpdate = (accountDetails) => {
    setSelectedBank(accountDetails);
    setUpdateBankModal(true);
  };

  const handelDelete = () => {
    deleteBankAccount(selectedBankId)
      .then(() => {
        dispatch(
          showSuccessNotification("Bank Account Deleted successfully !!!")
        );
        dispatch(
          fetchBankAccounts({
            limit: 100,
            page: 1,
          })
        );
        setDeleteModal(false);
      })
      .catch((e) => {
        dispatch(showErrorNotification(e.toString()));
      });
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t("Bank Accounts/USDT")}</title>
      </MetaTags>
      <Container>
        <div className="wallet-page h-100 ">
          <div className="d-flex justify-content-between m-4">
            <h1
              className="color-primary"
              style={{
                zIndex: 2,
              }}
            >
              {t("Bank Accounts/USDT Methods")}
            </h1>
            <Button
              type="button"
              className="color-bg-btn border-0 m-2 p-2 btn-sm w-md"
              style={{
                zIndex: 2,
              }}
              onClick={() => {
                dispatch(toggleCurrentModal("SelectWithdrawalWayModal"));
              }}
            >
              {t("Add Bank Account/USDT")}
            </Button>
          </div>
          {bankAccounts?.length < 1 && (
            <CardWrapper className="mb-3 total-balance p-4 shadow glass-card">
              <Row className="align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-center">
                  <h4 className="color-primary">{"No bank accounts"}</h4>
                </div>
              </Row>
            </CardWrapper>
          )}
          {bankAccounts?.map((bankAccount, index) => (
            <CardWrapper
              key={index}
              className="mb-3 total-balance p-4 glass-card shadow"
            >
              <Row className="align-items-center justify-content-between">
                <Col lg={2}>
                  <div className="wallets__total">
                    {bankAccount.type === "USDT" ? (
                      <>
                        <div className="wallets__title h6 text-nowrap">
                          {bankAccount.network}{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#ff6600",
                              marginLeft: "15px",
                            }}
                          >
                            {bankAccount.type}
                          </span>
                        </div>
                        <div className="total-balance-container">
                          <div className="balance-price">
                            {bankAccount.address}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="wallets__title h6 text-nowrap">
                          {bankAccount.bankName}{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#ff6600",
                              marginLeft: "15px",
                            }}
                          >
                            {bankAccount.type}
                          </span>
                        </div>
                        <div className="total-balance-container">
                          <div className="h4 text-nowrap">
                            {bankAccount.accountHolderName}
                          </div>
                        </div>
                        <div className="balance-price">
                          {bankAccount.accountNumber}
                        </div>
                      </>
                    )}
                  </div>
                </Col>
                <Col lg={7} className="wallet-btns">
                  {bankAccount.type === "USDT" ? (
                    <>
                      <Button
                        type="button"
                        className="border-0 color-bg-btn w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setShowUsdtModal(true);
                        }}
                      >
                        Show
                      </Button>
                      <Button
                        type="button"
                        className="btn-success w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setUpdateUsdtModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        className="border-0 color-bg-btn w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setShowBankModal(true);
                        }}
                      >
                        Show
                      </Button>
                      <Button
                        type="button"
                        className="btn-success w-lg"
                        onClick={() => {
                          setSelectedBank(bankAccount);
                          setUpdateBankModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                  <Button
                    type="button"
                    className="btn-danger w-lg"
                    onClick={() => {
                      setDeleteModal(true);
                      setSelectedBankId(bankAccount._id);
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </CardWrapper>
          ))}
        </div>
        <ZendeskChat />
      </Container>
      <ShowDetails
        isOpen={showBankModal}
        toggleOpen={() => {
          setShowBankModal(false);
        }}
        BankAccountData={selectedBank}
      ></ShowDetails>
      <ShowUsdtMethodDetails
        isOpen={showUsdtModal}
        toggleOpen={() => {
          setShowUsdtModal(false);
        }}
        BankAccountData={selectedBank}
      ></ShowUsdtMethodDetails>
      <EditBankAccountModal
        isOpen={updateBankModal}
        toggleOpen={() => {
          setUpdateBankModal(false);
        }}
        BankAccountData={selectedBank}
      ></EditBankAccountModal>
      <EditUsdtMethodModal
        isOpen={updateUsdtModal}
        toggleOpen={() => {
          setUpdateUsdtModal(false);
        }}
        BankAccountData={selectedBank}
      ></EditUsdtMethodModal>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handelDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.crypto.bankAccounts.loading,
  bankAccounts: state.crypto.bankAccounts.bankAccounts.docs,
  error: state.crypto.bankAccounts.error,
});

export default connect(mapStateToProps, null)(withTranslation()(BankAccounts));
