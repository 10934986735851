// import { useSelector } from "react-redux";
import CardWrapper from "components/Common/CardWrapper";
import PageHeader from "components/Forex/Common/PageHeader";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import {
  AvField, AvForm, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio
} from "availity-reactstrap-validation";
import {
  Row, Col, Input, Container, Label, Button,
  Alert
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createAccount, getAccountTypesStart, showErrorNotification, showSuccessNotification
} from "store/actions";
import {
  useEffect, useState
} from "react";
import ZendeskChat from "zendesk";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "components/Common/Loader";
import { applyNamesakeAccount, getPromotion } from "apis/forex/ib";

const confirmPassword = (value, ctx, input, cb) => {
  if (value !== ctx.password) cb("Password doesn't match!");
  else cb(true);
};

function NamesakeForm(props) {
  try {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const rebateRef = props.rebateRef ? JSON.parse(props.rebateRef) : null;
    const salesRef = props.salesRef;
    const { promotionLink } = props;

    console.log("rebateRef", rebateRef);
    console.log("salesRef", salesRef);
    console.log("promotionLink", promotionLink);
    const [loading, setLoading] = useState(false);
    const [promotion, setPromotion] = useState(null);
    const [accountCurrency, setSAccountCurrency] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const loadPromotion = async () => {
      try {
        setLoading(true);
        const res = await getPromotion(props.promotionLink);
        setPromotion(res.result);
      } catch (error) {
        throw new Error("Failed to load referral link");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      loadPromotion();
    }, [props.promotionLink]);

    const handleValidSubmit = async (e, v) => {
      try {
        setSubmitting(true);
        console.log("Creating namesake account with:", {
          values: v,
          platform: promotion?.accountType?.platform,
          promotionLink,
          rebateRef,
          salesRef,
          group: promotion?.defaultMTGroup?.mtGroupName,
        });
        const res = await applyNamesakeAccount({
          ...v,
          platform: promotion?.accountType?.platform,
          promotionLink,
          ...(rebateRef && { rebateRef }),
          ...(salesRef && { salesRef }),
          group: promotion?.defaultMTGroup?.mtGroupName,
        });
        dispatch(showSuccessNotification("Successfully created namesake account"));
        history.push("/partnership");
      } catch (error) {
        dispatch(showErrorNotification(error?.message));
      } finally {
        setSubmitting(false);
      }
    };
    
    if (loading) return <Loader />;

    if (!promotion && !promotionLink) throw new Error("Failed to load referral link");

    
    return (
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row className="border rounded-3 p-3 gx-1">
          <h5>{t("Platforms")}</h5>
          <Col key={promotion?.accountType?.platform} md="6" className="gy-3 px-2">
            <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
              <Input
                className="mt-0 me-2"
                id={promotion?.accountType?.platform}
                name="platform"
                type="radio"
                value={promotion?.accountType?.platform}
              />
              <Label check for={promotion?.accountType?.platform}>
                {t(promotion?.accountType?.platform)}
              </Label>
            </div>
          </Col>
        </Row>
              
        <AvRadioGroup name="accountTypeId" className="border rounded-3 mt-3" required>
          <Row className="p-3">
            <h5>{t("Account Type")}</h5>
            <Col key={promotion?.accountType?._id} md="6" className="gy-3 mt-3">
              <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                <AvRadio
                  label={t(promotion?.accountType?.title)}
                  value={promotion?.accountType?._id}
                  onClick={() => {
                    setSAccountCurrency(promotion?.accountType?.currencies);
                  }}
                />
              </div>
            </Col>
          </Row>
        </AvRadioGroup>

        {accountCurrency.length > 0 &&
          <Row className="gx-1">
            <AvRadioGroup name="currency" className="border rounded-3 mt-3" required>
              <Row className="p-3">
                <h5>{t("Account Currency")}</h5>
                {accountCurrency.map((currency) =>
                  <Col key={currency.currency} md="6" className="gy-3">
                    <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                      <AvRadio label={t(currency.currency)} value={currency.currency} />
                    </div>
                  </Col>
                )}
              </Row>
            </AvRadioGroup>
          </Row>
        }
        <Row className="border rounded-3 p-3 mt-3 gx-1">
          <h5 className="mb-3">{t("Select Leverage")}</h5>
          <AvField type="select" name="leverage" value={"1"} required>
            {promotion?.accountType && promotion?.accountType?.leverages.length > 0 &&
              promotion?.accountType?.leverages.map((leverage) => (
                <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
              ))
            }
          </AvField>
        </Row>
        <Row className=" border rounded-3 p-3 mt-3 gx-1">
          <Col md="6">
            <AvGroup className="pe-2">
              <Label for="password" className="mb-2">{t("Trading Account Password")}</Label>
              <AvInput name="password" type="password" id="password" required />
            </AvGroup>
          </Col>
          <Col md="6">
            <AvGroup className="ps-2">
              <Label for="confirm_password" className="mb-2">{t("Confirm Password")}</Label>
              <AvInput name="confirm_password" type="password" id="password" required
                validate={{ custom: confirmPassword }}
              />
              <AvFeedback>{t("Password doesn't match")}</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
      
        <div className="text-center mt-3 mb-1">
          <Button type="submit" disabled={submitting} className="shadow color-bg-btn w-25">{t("Submit")}</Button>
        </div>
      </AvForm>
    );
  } catch (error) {
    return null;
  }
}

function CreateAccountType({ error, isError }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAccountTypesStart());
  }, []);

  const { accountTypes, submitting } = useSelector((state) => state.forex.accounts);
  const { clientData } = useSelector(state => state.Profile);

  const [filteredAccountTypes, setFilteredAccountTypes] = useState([]);
  const [platformAccountTypes, setPlatformAccountTypes] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [promoLinkPlatforms, setPromoLinkPlatforms] = useState(null);
  const [accountCurrency, setSAccountCurrency] = useState([]);
  // filter accounts based on account's type (live/demo)
  useEffect(() => {
    setFilteredAccountTypes(accountTypes?.filter((at) => at.type === "LIVE" && at.isDefault));
  }, [accountTypes]);

  // get unique platforms
  const platforms = accountTypes.length > 0 && [...new Set(accountTypes.filter((type) => type.isDefault).map(item => item.platform))];

  // filter accounts based on platform
  useEffect(() => {
    setPlatformAccountTypes(filteredAccountTypes?.filter((account) => account.platform === selectedPlatform));
  }, [selectedPlatform]);
  useEffect(() => {
    if (clientData?.promoLink) {
      let platformsSet = new Set();
      clientData?.promoLink?.forEach(promo => {
        platformsSet.add(promo?.accountType?.platform)
      })
      setPromoLinkPlatforms([...platformsSet]);
    }
  }, [clientData?.promoLink, selectedPlatform]);

  const handleValidSubmit = (event, values) => {
    delete values.confirm_password;
    dispatch(createAccount({
      ...values,
    }));
  };

  return (
    
    <AvForm
      onValidSubmit={(e, v) => {
        handleValidSubmit(e, v);
      }}
    >
      {isError ? 
        <Alert color="danger">{error}</Alert>
        :
      <>
      
        {platforms &&
          <Row className="border rounded-3 p-3 gx-1">
            <h5>{t("Platforms")}</h5>
            {clientData?.promoLink && promoLinkPlatforms?.length > 0 ? (
              promoLinkPlatforms?.map((promo) => (
                <Col key={promo} md="6" className="gy-3 px-2">
                  <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                    <Input
                      className="mt-0 me-2"
                      id={promo}
                      name="platform"
                      type="radio"
                      value={promo}
                      onClick={() => {
                        setSelectedPlatform(promo);
                      }}
                    />
                    <Label check for={promo}>
                      {t(promo)}
                    </Label>
                  </div>
                </Col>
              ))
            ) : (
              platforms.map((platform) => (
                <Col key={platform} md="6" className="gy-3 px-2">
                  <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                    <Input
                      className="mt-0 me-2"
                      id={platform}
                      name="platform"
                      type="radio"
                      value={platform}
                      onClick={() => {
                        setSelectedPlatform(platform);
                      }}
                    />
                    <Label check for={platform}>
                      {t(platform)}
                    </Label>
                  </div>
                </Col>
              ))
            )}
          </Row>
        }
        {selectedPlatform && (
          <AvRadioGroup name="accountTypeId" className="border rounded-3 mt-3" required>
            <Row className="p-3">
              <h5>{t("Account Type")}</h5>
              {selectedPlatform && clientData?.promoLink?.length > 0 ? (
                clientData?.promoLink?.map((promo) => (
                  promo?.accountType?.platform === selectedPlatform && (
                    <Col key={promo?.accountType?._id} md="6" className="gy-3 mt-3">
                      <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                        <AvRadio
                          label={t(promo?.accountType?.title)}
                          value={promo?.accountType?._id}
                          onClick={() => {
                            setSAccountCurrency(promo?.accountType?.currencies);
                            setSelectedPromo(promo);
                          }}
                        />
                      </div>
                    </Col>
                  )
                ))
              ) : (
                filteredAccountTypes.length > 0 &&
                        filteredAccountTypes.map((type) => (
                          <Col key={type._id} md="6" className="gy-3 mt-3">
                            <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                              <AvRadio
                                label={t(type.title)}
                                value={type._id}
                                onClick={() => {
                                  setSAccountCurrency(type.currencies);
                                }}
                              />
                            </div>
                          </Col>
                        ))
              )}
            </Row>
          </AvRadioGroup>
        )}

        {/* {accountCurrency.length > 0 &&
          <Row>
            <AvRadioGroup name="currency" className="border rounded-3 mt-3" required>
              <Row className="p-3">
                <h5>{t("Account Currency")}</h5>
                <Row className="p-3">
                  <h5>{t("Account Currency")}</h5>
                  {
                    clientData?.promoLink ?
                      <Col md="6" className="gy-3">
                        <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                          <AvRadio label={t("USD")} value={"USD"} />
                        </div>
                      </Col>
                    :
                      accountCurrency.map((currency) =>
                        <Col key={currency.currency} md="6" className="gy-3">
                          <div className="d-flex align-items-center border rounded-3 p-2 bg-light">
                            <AvRadio label={t(currency.currency)} value={currency.currency} />
                          </div>
                        </Col>
                      )
                  }
                </Row>
              </Row>
            </AvRadioGroup>
          </Row>
        } */}
        <Row className="border rounded-3 p-3 mt-3 gx-1">
          <h5 className="mb-3">{t("Select Leverage")}</h5>
          <AvField type="select" name="leverage" value={"1"} required>
            {selectedPromo && Object.entries(selectedPromo).length > 0 ? (
              selectedPromo?.accountType?.leverages.map((leverage) => (
                <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
              ))
            ) : (
              filteredAccountTypes?.[0]?.leverages.map((leverage) => (
                <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
              ))
            )}
          </AvField>
        </Row>
        <Row className=" border rounded-3 p-3 mt-3 gx-1">
          <Col md="6">
            <AvGroup className="pe-2">
              <Label for="password" className="mb-2">{t("Trading Account Password")}</Label>
              <AvInput name="password" type="password" id="password" required />
            </AvGroup>
          </Col>
          <Col md="6">
            <AvGroup className="ps-2">
              <Label for="confirm_password" className="mb-2">{t("Confirm Password")}</Label>
              <AvInput name="confirm_password" type="password" id="password" required
                validate={{ custom: confirmPassword }}
              />
              <AvFeedback>{t("Password doesn't match")}</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
      
        <div className="text-center mt-3 mb-1">
          <Button type="submit" disabled={submitting} className="shadow color-bg-btn w-25">{t("Submit")}</Button>
        </div>
      </>
      }
    </AvForm>
  );
}

function TradingAccount() {
  const location = useLocation();
  const [namesake, setNamesake] = useState(location?.state?.namesake ? location?.state : null);

  useEffect(() => {
    console.log("Location state:", location?.state);
    console.log("Namesake state:", namesake);
    setNamesake(location?.state?.namesake ? location?.state : null);
  }, [location?.state]);

  const { t } = useTranslation();

  const formTitle = namesake ? "Create A Namesake Account" : "Create A Trading Account";
  console.log("namesake", namesake);
  const form = !namesake ? (
    <CreateAccountType
      error={location?.state?.error}
      isError={location?.state?.isError}
    />
  ) : <NamesakeForm 
    rebateRef={namesake?.rebateRef} 
    salesRef={namesake?.salesRef}
    promotionLink={namesake?.promotionLink} 
  />;

  return (<>
    <MetaTags>
      <title>{t("Trading Account")}</title>
    </MetaTags>
    <div className="page-content">
      <Container className="pt-5">
        <PageHeader title="Trading Account"></PageHeader>
        <CardWrapper className="mt-4">
          <div className="d-flex justify-content-between heading pb-2">
            <h5>{t(formTitle)}</h5>
            <div>
              <svg width="3" height="15" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" fill="#74788D" />
                <circle cx="2" cy="9" r="2" fill="#74788D" />
                <circle cx="2" cy="16" r="2" fill="#74788D" />
              </svg>
            </div>
          </div>
          <Row className="mt-3">
            <Col>
              {form}
            </Col>
          </Row>
        </CardWrapper>
      </Container>
    </div>
    <ZendeskChat />
  </>);
}

export default TradingAccount;