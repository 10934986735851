
import React from "react";
import OwlCarousel from "react-owl-carousel";
// import { Col } from "reactstrap";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { withTranslation } from "react-i18next";
import def from "assets/img/figures-1.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MANAGER_LINK } from "config";
import { INVESTOR_LINK } from "config";

const Slider = (props) => {
  const history = useHistory();
  return (
    <div className='slider slider_home mb-3'>
      <div className='slider__container'>
        <OwlCarousel style={{ direction: "ltr" }} className="owl-theme" loop items={1}>
          <div className="slider__item  justify-content-center align-items-center p-relative">
            <div className="slider__preview"><img src={def} alt="" /></div>
          </div> 
          <div className="slider__item  justify-content-center align-items-center p-relative">
            <div className="slider__preview"><img src={def} alt="" /></div>
          </div>
          {/* <div className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className=""><img style={{
              cursor: "pointer"
            }} src={def} alt="" onClick={()=> {
              window.open(INVESTOR_LINK, "_blank");
            }} /></div>
          </div> 
          <div className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className=""><img style={{
              cursor: "pointer"
            }} src={def} alt="" onClick={()=> {
              window.open(MANAGER_LINK, "_blank");
            }} /></div>
          </div>  */}
          {/* <div className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className=""><img style={{
              cursor: "pointer"
            }} src={s3} alt="" onClick={()=> {
              history.push("/request-investor");
            }} /></div>
          </div> 
          <div className="justify-content-center align-items-center p-relative"
            style={{
              margin: 0,
              padding: 0,
              borderRadius: 25,
              overflow: "hidden",
            }}
          >
            <div className=""><img style={{
              cursor: "pointer"
            }} src={s4} alt="" onClick={()=> {
              history.push("/request-master");
            }} /></div>
          </div> */}
        </OwlCarousel>
      </div>
    </div>
  );
};
export default withTranslation()(Slider); 