/**
 * React component for adding a USDT address method.
 *
 * This component renders a modal form for adding a USDT address with network selection.
 * It interacts with Redux store to fetch and add bank accounts, and displays success or error notifications.
 * Uses Reactstrap components for UI elements and React-i18next for translation.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isOpen - Flag indicating if the modal is open.
 * @param {function} props.toggleOpen - Function to toggle the modal open/close.
 */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchBankAccounts } from "../../store/actions";

import {
  Button,
  Container,
  Input,
  Label,
  Modal,
  FormFeedback,
} from "reactstrap";
import { addBankAccount } from "../../apis/bankAccounts";
import {
  showErrorNotification,
  showSuccessNotification,
} from "store/general/notifications/actions";
import { useTranslation } from "react-i18next";

function AddUsdtMethodModal({ isOpen, toggleOpen = () => {} }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [network, setNetwork] = useState("TRC20"); // Default network is TRC20
  const [usdtAddress, setUsdtAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    usdtAddress: "",
  });

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    addBankAccount({
      network: network,
      address: usdtAddress,
      currency: "USD",
      type: "USDT",
    })
      .then(() => {
        dispatch(
          showSuccessNotification("USDT Address Added successfully !!!")
        );
        toggleOpen();
        dispatch(
          fetchBankAccounts({
            limit: 100,
            page: 1,
          })
        );
      })
      .catch((e) => {
        setLoading(false);
        dispatch(showErrorNotification(e.toString()));
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleOpen}
        centered={true}
        size="lg"
        className="custom-modal"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close btn btn-soft-dark waves-effect waves-light btn-rounded m-4"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleOpen}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h6>{t("Add USDT Address")}</h6>
          <form onSubmit={handleSubmit}>
            <br />
            <Container>
              <div className="mb-3">
                <Label className="form-label mb-3">{t("Network")}</Label>
                <select
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)} // Allow user to change the network
                  className="form-control"
                >
                  <option value="TRC20">TRC20</option>
                </select>
              </div>

              <div className="mb-3">
                <Label className="form-label mb-3">{t("USDT Address")}</Label>
                <Input
                  onChange={(e) => {
                    setUsdtAddress(e.target.value);
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Enter USDT Address"
                />
                {validation.usdtAddress && (
                  <FormFeedback>{validation.usdtAddress}</FormFeedback>
                )}
              </div>

              <div className="text-center">
                <Button
                  className="btn btn-secondary m-2 btn-sm w-lg"
                  onClick={() => toggleOpen()}
                >
                  {t("Cancel")}
                </Button>

                <Button
                  className="btn btn-danger m-2 btn-sm w-lg"
                  type="submit"
                  disabled={loading}
                >
                  {t("Add")}
                </Button>
              </div>
            </Container>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddUsdtMethodModal;
