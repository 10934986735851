import { useEffect } from "react";

const ZendeskChat = () => {
  useEffect(() => {
    // Check if the script is already added to avoid duplication
    if (!document.getElementById("ze-snippet")) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=79b592e3-0f46-45a9-846d-402a232ff5c9";
      script.async = true;

      document.body.appendChild(script);

      // Optional: Cleanup the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return null; // The widget is handled by the script, no need to render anything here
};

export default ZendeskChat;
