import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { markNotificationRead } from "store/actions";

const NotificationDropdownItem = ({ notification }) => {
  const dispatch = useDispatch();
  function dateFormatter(val) {
    let d = new Date(val.createdAt);
    d = `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`;
    return d;
  }

  const markAsRead = () => {
    dispatch(
      markNotificationRead({
        notificationIds: [notification._id],
      })
    );
  };

  return (
    <div className="notification-item">
      <Link to={notification.data.cpClickUrl} onClick={markAsRead}>
        <div className="d-flex align-items-start">
          <img src={notification.icon} alt="icon" className="me-3 rounded-circle avatar-sm" />
          <div className="flex-grow-1">
            <h6 className="mb-1">{notification.title}</h6>
            <p className="mb-0">{notification.body}</p>
            <small className="text-muted">
              {dateFormatter(notification)}
            </small>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NotificationDropdownItem;
