import {
  ADD_PROMO_START,
  ADD_PROMO_END,
  FETCH_PROMO_START,
  FETCH_PROMO_END,
  EDIT_PROMO_START,
  EDIT_PROMO_END,
  DELETE_PROMO_START,
  DELETE_PROMO_END
} from "./actionTypes";

// fetch promo
export const fetchPromoStart = (params = {}) => {
  return {
    type: FETCH_PROMO_START,
    payload: params
  };
};

export const fetchPromoEnd = (data) => {
  return {
    type: FETCH_PROMO_END,
    payload: data
  };
};

// export const fetchPromoFail = (error) => {
//   return {
//     type: FETCH_PROMO_FAIL,
//     payload: { error }
//   };
// };

// add promo
export const addPromoStart = (params = {}) => {
  return {
    type: ADD_PROMO_START,
    payload: params
  };
};

export const addPromoEnd = (data) => {
  return {
    type: ADD_PROMO_END,
    payload: data
  };
};

// export const addPromoFail = (error) => {
//   return {
//     type: ADD_PROMO_FAIL,
//     payload: { error }
//   };
// };

// delete promo
export const deletePromoStart = (params = {}) => {
  return {
    type: DELETE_PROMO_START,
    payload: params
  };
};

export const deletePromoEnd = (data) => {
  return {
    type: DELETE_PROMO_END,
    payload: data
  };
};

// edit promo
export const editPromoStart = (params = {}) => {
  return {
    type: EDIT_PROMO_START,
    payload: params
  };
};

export const editPromoEnd = (data) => {
  return {
    type: EDIT_PROMO_END,
    payload: data
  };
};

// export const editPromoClear = (data) => {
//   return {
//     type: EDIT_PROMO_CLEAR,
//     payload: data
//   };
// };
