import * as axiosHelper from "./api_helper";
import qs from "qs";

export const addPromolink = async ({ payload }) => {
  const data = await axiosHelper.post("/promolink", payload);
  if (data.isError) {
    throw new Error(data.message);
  }

  return data;
};

export const getPromolink = async ({ payload }) => {
  const data = await axiosHelper.get(`/promolink?${qs.stringify(payload)}`);
  if (data.isError) {
    console.log(data);
    throw new Error(data.isError);
  }
  return data.result;
};

export const deletePromolink = async (payload) => {
  // const { id, values } = payload;
  const data = await axiosHelper.del("/promolink/" + payload.id);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const editPromolink = async (params) => {
  const id = params.id;
  delete params.id;
  const data = await axiosHelper.patch("/promolink/" + id, params);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};